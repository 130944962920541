import React from 'react'
import classes from "./Button3d.module.css";

const Button3d = ({text,skipHandler}) => {
    function skipHandlerButton(){
        skipHandler();
    }
  return (
    <button type="button"
     onClick={skipHandlerButton}
     >
    <span className={classes.shadow}></span>
    <span className={classes.edge}></span>
    <span className={`${classes.front} ${classes.text}`}>
      {" "}
      {text}
    </span>
  </button>

  )
}

export default Button3d