import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("modalQuestion")?
JSON.parse(localStorage.getItem("modalQuestion")):
{
    modalQuestion:null
}

const modalQuestionSlice=createSlice({
    name:"modalQuestion",
    initialState,
    reducers:{
        storeModalQuestion:(state,action)=>{
            state.modalQuestion=action.payload;
            localStorage.setItem("modalQuestion",JSON.stringify(state));
            return state;
        },
        resetModalQuestion:(state,action)=>{
            state.modalQuestion=null;
            localStorage.removeItem("modalQuestion");
            return state;
        }
    }
})

export const {storeModalQuestion,resetModalQuestion}=modalQuestionSlice.actions;
export default modalQuestionSlice;