import React from "react";
import Lottie from "lottie-react";
import classes from "./QuizAnimation.module.css";
import quizAnimation from "../../StaticAnimations/quizAnimation.json";

const QuizAnimation = () => {
  return (
    <div className={classes.quizAnimationContainer}>
      <Lottie
        animationData={quizAnimation}
        loop={true}
        autoplay={true}
        className={classes.quizAnimation}
      />
    </div>
  );
};

export default QuizAnimation;
