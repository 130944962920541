import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("stepper")?
JSON.parse(localStorage.getItem("stepper")):
{
    stepper:[]
}

// stepper:[{
//     index:0,
//     correct:true/false,
//     skip:true/false
// }]

const stepperSlice=createSlice({
    name:"stepper",
    initialState,
    reducers:{
        storeStepper:(state,action)=>{
            state.stepper=[...state.stepper,action.payload];
            localStorage.setItem("stepper",JSON.stringify(state));
            return state;
        },
        resetStepper:(state,action)=>{
            state.stepper=[];
            localStorage.removeItem("stepper");
            return state;
        }
    }
})

export const {storeStepper,resetStepper}=stepperSlice.actions;
export default stepperSlice;