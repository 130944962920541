import React from "react";
import classes from "./Timer.module.css";
import timerAnimation from "../../StaticAnimations/timerAnimation.json";
import Lottie from "lottie-react";
import { LinearProgress } from "@mui/material";

const Timer = ({progress,time}) => {
  return (
    <div className="flex justify-center items-center gap-4">
      <Lottie
        animationData={timerAnimation}
        loop={true}
        autoplay={true}
        className={classes.timerAnimation}
      />
      <div className="w-full">
        <LinearProgress
          variant="determinate"
          value={progress}
          style={{ backgroundColor: "#ccc" }}
        />
      </div>
      <div>
        <h3 className={classes.timeText}>{time}</h3>
      </div>
    </div>
  );
};

export default Timer;
