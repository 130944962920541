import React, { useEffect, useState, useRef } from "react";
import classes from "./HomePage3.module.css";
import { Link, useNavigate } from "react-router-dom";
import InitialQuestionModal from "../Components/InitialQuestionModal";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../Slices/initialQuestionModalSlice";
import axios from "axios";
import { leaderboardApi, welcomeQuestionsApi } from "../Api/api";
import { storeModalQuestion } from "../Slices/modalQuestionSlice";
import { ResetTime, ResetTimeForProgress } from "../Slices/timeSlice";
import { resetIndex } from "../Slices/indexSlice";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import {
  resetCorrectIndex,
  resetWrongIndex,
} from "../Slices/correctWrongSlice";
import Loading from "../Components/Loading";
import { resetCorrect, resetWrong } from "../Slices/totalCorrectWrongSlice";
import { resetSkip } from "../Slices/skipSlice";
import { resetEasyQuestions } from "../Slices/easyQuestionsSlice";
import { resetMediumQuestions } from "../Slices/mediumQuestionsSlice";
import { resetHardQuestions } from "../Slices/hardQuestionsSlice";
import { resetStepper } from "../Slices/stepperSlice";
import Lottie from "lottie-react";
import leaderboardAnimation from "../StaticAnimations/leaderboardAnimation.json";
import award1Animation from "../StaticAnimations/award1.json";
import award2Animation from "../StaticAnimations/award2.json";
import award3Animation from "../StaticAnimations/award3.json";
import { resetPracticeState } from "../Slices/practiceQuestionsSlice";
import { resetOriginalCorrectIndex } from "../Slices/originalCorrectSlice";
import { openMobileMenu } from "../Slices/mobileMenuSlice";
import MobileDrawer from "../Components/MobileDrawer";
import InformationModal from "../Components/InformationModal";

const HomePage3 = () => {
  const [loading, setLoading] = useState(true);
  const [leaderboard, setLeaderboard] = useState([
    {
      ani: "87765657",
      score: "0",
      questionCount: "33",
    },
    {
      ani: "67869878",
      score: "0",
      questionCount: "42",
    },
    {
      ani: "90672184",
      score: "0",
      questionCount: "46",
    },
    {
      ani: "76399906",
      score: "0",
      questionCount: "60",
    },
  ]);
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, score } = useSelector((state) => state.userSlice);

  const handleNavigate = (path) => {
    dispatch(resetStepper());
    dispatch(resetEasyQuestions());
    dispatch(resetMediumQuestions());
    dispatch(resetHardQuestions());
    dispatch(resetSkip());
    dispatch(resetCorrect());
    dispatch(resetWrong());
    dispatch(resetCorrectIndex());
    dispatch(resetWrongIndex());
    dispatch(resetIndex());
    dispatch(ResetTime());
    dispatch(ResetTimeForProgress());
    dispatch(resetPracticeState());
    dispatch(resetOriginalCorrectIndex());
    navigate(path);
  };

  const openMobileMenuHandler = () => {
    dispatch(openMobileMenu());
  };

  const getWelcomeQuestionFromBackend = async () => {
    try {
      setLoading(true);

      let headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const res = await axios.get(`${welcomeQuestionsApi}&ani=${user}`, {
        headers: headers,
      });
      dispatch(storeModalQuestion(res?.data?.Welcome));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data?.message || error?.response?.message || error?.message
      );
    }
  };

  const getLeaderBoardScoresFromBackend = async () => {
    try {
      let headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const res = await axios.get(`${leaderboardApi}`, { headers: headers });
      console.log(res, "res");
      // if (res?.data?.top == "Not Found!") {
      //   setLeaderboard([]);
      // } else {
      //   setLeaderboard(res?.data?.top);
      // }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.data?.message || error?.message
      );
    }
  };
  useEffect(() => {
    if (!Cookies.get("token")) {
      navigate("/");
    } else {
      getWelcomeQuestionFromBackend();
      getLeaderBoardScoresFromBackend();
    }
  }, [Cookies]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        if (Cookies.get("donotShow") || score == 0) {
          return;
        }
        dispatch(openModal());
      }, 4000);
    }
  }, [dispatch, loading]);

  const { open } = useSelector((state) => state.modal);
  const { modalQuestion } = useSelector((state) => state.modalQuestion);

  const leaderboardPositionsRef = useRef(null);
  const leaderboardTableRef = useRef(null);
  const [heightOfLeaderBoard, setHeightOfLeaderBoard] = useState("");

  useEffect(() => {
    if (!loading && leaderboard?.length > 0) {
      const heightTable = leaderboardTableRef?.current?.clientHeight;
      const heightPosition = leaderboardPositionsRef?.current?.clientHeight;
      setHeightOfLeaderBoard(() => heightTable / 10 + heightPosition / 10 + 5);
    }
  }, [loading, leaderboardTableRef, leaderboardPositionsRef, leaderboard]);

  const cancelPopupHandler = () => {
    setPopup(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <main className={classes.header_section}>
            <div className={classes.navbar}>
              <div className={classes.logo}>
                <img
                  className={classes.logo_img}
                  // src="/assets/images/new logo.png"
                  src="/assets/images/newLogo.png"
                  alt="Zero Point Logo"
                />
              </div>
              <div className={classes.menu_list}>
                <a href="#how_to_play_section" className={classes.menu}>
                  How to play?
                </a>
                <a href="#leaderboard_section" className={classes.menu}>
                  Leaderboard
                </a>
                <a
                  href="#terms_and_conditions_section"
                  className={classes.menu}
                >
                  Terms & Conditions
                </a>
                <Link to="/user/account" className={classes.menu}>
                  Account
                </Link>
              </div>
              <div className={classes.menu_icon}>
                <i
                  className={`fa-solid fa-bars ${classes.icon}`}
                  onClick={openMobileMenuHandler}
                ></i>
              </div>
            </div>

            <div className={`${classes.container_section}`}>
              <div className={classes.container_img}>
                <img
                  src="/assets/images/phoneImage.png"
                  className={`${classes.img} wow bounceIn`}
                  alt="Tilted Phone"
                />
              </div>
              <div className={classes.container_intro}>
                <h4 className={`${classes.title} wow fadeInDown`}>
                  Zero <span style={{color:'#FFA500'}}>Point</span> , an reverse quiz game
                </h4>
                <p className={`${classes.paragraph} wow fadeInUp`}>
                  Users will be presented with a set of options, where three
                  options are correct, and one is intentionally incorrect. Users
                  are required to choose the incorrect option.
                </p>

                <div className={`${classes.buttons_container} wow bounceIn`}>
                  {score == 0 ? (
                    <button
                      className={`${classes.play_btn}`}
                      onClick={() => setPopup(true)}
                    >
                      Play Quiz
                    </button>
                  ) : (
                    <button
                      onClick={() => handleNavigate("/game/levels")}
                      className={`${classes.play_btn}`}
                    >
                      Play Quiz
                    </button>
                  )}
                  <button
                    className={classes.demo_btn}
                    onClick={() => handleNavigate("/game/practice")}
                  >
                    Practice Quiz
                  </button>
                </div>
              </div>
            </div>
          </main>

          <section
            className={classes.how_to_play_section}
            id="how_to_play_section"
          >
            <div className={classes.how_to_play_title}>
              <h3>How to Play?</h3>
            </div>
            <div className={classes.card_grid}>
              <div className={`${classes.card_play_1}  wow bounceIn`}>
                <div className={classes.number_image_container}>
                  <img
                    src="/assets/images/number-1.png"
                    className={classes.number_image}
                    alt="Point 1"
                  />
                </div>
                <div className={classes.play_description}>
                  <p className={classes.play_paragraph}>
                    Users will be presented with a set of options, where three
                    options are correct, and one is intentionally incorrect.
                    Users are required to choose the incorrect option.
                  </p>
                </div>
              </div>
              <div className={`${classes.card_play_2} wow bounceIn`}>
                <div className={classes.number_image_container}>
                  <img
                    src="/assets/images/number-2.png"
                    className={classes.number_image}
                    alt="Point 1"
                  />
                </div>
                <div className={classes.play_description}>
                  <p className={classes.play_paragraph}>
                    When user clicks on the incorrect option, than a tick mark
                    will be indicated that you are right and your points will be
                    deducted.
                  </p>
                </div>
              </div>

              <div className={`${classes.card_play_3}  wow bounceIn`}>
                <div className={classes.number_image_container}>
                  <img
                    src="/assets/images/number-3.png"
                    className={classes.number_image}
                    alt="Point 1"
                  />
                </div>
                <div className={classes.play_description}>
                  <p className={classes.play_paragraph}>
                    To win this game , User is required to reach the zero point
                    by choosing the incorrect option out of 3 correct options.
                  </p>
                </div>
              </div>
              <div className={`${classes.card_play_3} wow bounceIn`}>
                <div className={classes.number_image_container}>
                  <img
                    src="/assets/images/number-4.png"
                    className={classes.number_image}
                    alt="Point 1"
                  />
                </div>
                <div className={classes.play_description}>
                  <p className={classes.play_paragraph}>
                    The game concludes either when the user fails to complete
                    the quiz within 60 seconds or provides three correct
                    answers, resulting in your defeat.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {leaderboard.length > 0 ? (
            <div
              className={`${classes.leaderboard_container} wow bounceIn`}
              style={{ height: `${heightOfLeaderBoard}rem` }}
              id="leaderboard_section"
            >
              <div className={classes.leaderAnimation_container}>
                <Lottie
                  animationData={leaderboardAnimation}
                  loop={true}
                  autoplay={true}
                  className={classes.leaderboardAnimation}
                />
              </div>
              <div className={classes.leaderboard_positions_container}>
                <div
                  className={classes.leaderboard_top3_positions}
                  ref={leaderboardPositionsRef}
                >
                  <div className={classes.position2}>
                    <div className={classes.position2_animation_container}>
                      <Lottie
                        animationData={award2Animation}
                        loop={true}
                        autoplay={true}
                        className={classes.award2Animation}
                      />
                    </div>
                    <div className={classes.player_info}>
                      <h3 className={classes.player_text}>
                        <span>User: </span>
                        {leaderboard[1]?.ani}
                      </h3>
                      <h3 className={classes.player_text}>
                        <span>Score: </span>
                        {leaderboard[1]?.score}
                      </h3>
                    </div>
                  </div>
                  <div className={classes.position1}>
                    <div className={classes.position1_animation_container}>
                      <Lottie
                        animationData={award1Animation}
                        loop={true}
                        autoplay={true}
                        className={classes.award1Animation}
                      />
                    </div>
                    <div className={classes.player_info}>
                      <h3 className={classes.player_text}>
                        <span>User: </span>
                        {leaderboard[0]?.ani}
                      </h3>
                      <h3 className={classes.player_text}>
                        <span>Score: </span>
                        {leaderboard[0]?.score}
                      </h3>
                    </div>
                  </div>
                  <div className={classes.position3}>
                    <div className={classes.position3_animation_container}>
                      <Lottie
                        animationData={award3Animation}
                        loop={true}
                        autoplay={true}
                        className={classes.award3Animation}
                      />
                    </div>
                    <div className={classes.player_info}>
                      <h3 className={classes.player_text}>
                        <span>User: </span>
                        {leaderboard[2]?.ani}
                      </h3>
                      <h3 className={classes.player_text}>
                        <span>Score: </span>
                        {leaderboard[2]?.score}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className={classes.leaderboard_table_container}>
                  <div
                    className={classes.leaderboard_table}
                    ref={leaderboardTableRef}
                  >
                    <div className={classes.leaderboard_table_header}>
                      <div className={classes.leaderboard_column1}>
                        <h3 className={classes.column_name}>Rank</h3>
                      </div>
                      <div className={classes.leaderboard_column2}>
                        <h3 className={classes.column_name}>User</h3>
                      </div>
                      <div className={classes.leaderboard_column3}>
                        <h3 className={classes.column_name}>Score</h3>
                      </div>
                      <div className={classes.leaderboard_column3}>
                        <h3 className={classes.column_name}>Questions</h3>
                      </div>
                    </div>

                    {leaderboard.map((data, i) => {
                      return (
                        <div className={classes.leaderboard_table_data} key={i}>
                          <div className={classes.leaderboard_row1}>
                            <h3 className={classes.row_name}>{i + 1}</h3>
                          </div>
                          <div className={classes.leaderboard_row2}>
                            <h3 className={classes.row_name}>{data?.ani}</h3>
                          </div>
                          <div className={classes.leaderboard_row3}>
                            <h3 className={classes.row_name}>{data?.score}</h3>
                          </div>
                          <div className={classes.leaderboard_row3}>
                            <h3 className={classes.row_name}>
                              {data?.questionCount}
                            </h3>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={classes.leaderboard_container_2}
              style={{ height: `10rem` }}
              id="leaderboard_section"
            >
              <h3>No Players won yesterday!</h3>
            </div>
          )}

          <section
            className={`${classes.terms_conditions_section} wow bounceIn`}
            id="terms_and_conditions_section"
          >
            <div className={classes.terms_title}>
              <h3>Terms & Conditions</h3>
            </div>
            <div className={classes.terms_paragraph}>
              <div className={classes.terms_paragraph_sub_container}>
                <i className={`fa-solid fa-circle ${classes.circle}`}></i>
                <p className={classes.term_paragraph}>
                  These terms and conditions govern the use of the "ZeroPoint"
                  service (hereinafter referred to as "the Service") provided by
                  Visiontrek Communication. By accessing or using the Service,
                  you agree to comply with and be bound by these terms. If you
                  do not agree with any part of these terms, you may not use the
                  Service.
                </p>
              </div>
              <div className={classes.terms_paragraph_sub_container}>
                <i className={`fa-solid fa-circle ${classes.circle}`}></i>
                <p className={classes.term_paragraph}>
                  Users will be presented with a set of options, where three
                  options are correct, and one is intentionally incorrect. Users
                  are required to choose the incorrect option.
                </p>
              </div>
              <div className={classes.terms_paragraph_sub_container}>
                <i className={`fa-solid fa-circle ${classes.circle}`}></i>
                <p className={classes.term_paragraph}>
                  To use the Service, there is no age limit. But users must
                  provide accurate and complete information during the
                  registration process.
                </p>
              </div>
            </div>
            <div className={classes.terms_button_container}>
              <button
                onClick={() => navigate("/terms")}
                className={classes.terms_button}
              >
                Read More
              </button>
            </div>
          </section>
          <InitialQuestionModal open={open} modalQuestion={modalQuestion} />
          {popup && <InformationModal cancelHandler={cancelPopupHandler} />}
          <MobileDrawer />
        </>
      )}
    </>
  );
};

export default HomePage3;
