import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthWithScore = ({ children }) => {
  const navigate = useNavigate();
  const { score } = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (score == 0) {
      navigate("/home");
    }
  }, [navigate]);

  return <>{children}</>;
};

export default AuthWithScore;
