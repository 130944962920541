import React from "react";
import classes from "./ScoreBox.module.css";

const ScoreBox = ({score}) => {
  return (
    <div className={classes.scoreContainer}>
      <h1 className={classes.score}>Score : {score}</h1>
    </div>
  );
};

export default ScoreBox;
