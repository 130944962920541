import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const Auth = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const cookieValue = Cookies.get('token'); 
    if (!cookieValue) {
      navigate('/');
    }
  }, [navigate]);

  return <>{children}</>;
};

export default Auth;
