import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("totalCorrectWrong")?
JSON.parse(localStorage.getItem("totalCorrectWrong")):
{
    totalCorrect:0,
    totalWrong:0,
}

const totalCorrectWrongSlice=createSlice({
    name:"totalCorrectWrong",
    initialState,
    reducers:{
        increaseCorrect:(state,action)=>{
            state.totalCorrect=state.totalCorrect+1;
            localStorage.setItem("totalCorrectWrong",JSON.stringify(state));
            return state;
        },
        resetCorrect:(state,action)=>{
            state.totalCorrect=0;
            localStorage.removeItem("totalCorrectWrong");
            return state;
        },
        increaseWrong:(state,action)=>{
            state.totalWrong=state.totalWrong+1;
            localStorage.setItem("totalCorrectWrong",JSON.stringify(state));
            return state;
        },
        resetWrong:(state,action)=>{
            state.totalWrong=0;
            localStorage.setItem("totalCorrectWrong",JSON.stringify(state));
            return state;
        },
    }
})

export const {increaseCorrect,resetCorrect,increaseWrong,resetWrong}=totalCorrectWrongSlice.actions;
export default totalCorrectWrongSlice;