import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Auth from "./Components/Auth/Auth";
import LoginPage from "./Routes/LoginPage";
import WinnerPage from "./Routes/WinnerPage";
import LosePage from "./Routes/LosePage";
import SignupPage from "./Routes/SignupPage";
import EasyGamePage2 from "./Routes/EasyGamePage2";
import MediumGamePage2 from "./Routes/MediumGamePage2";
import HardGamePage2 from "./Routes/HardGamePage2";
import GameLevels from "./Routes/GameLevels";
import PracticeGamePage from "./Routes/PracticeGamePage";
import TermsPage from "./Routes/TermsPage";
import HomePage3 from "./Routes/HomePage3";
import Account from "./Routes/Account";
import AuthWithScore from "./Components/Auth/AuthWithScore";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/signup",
      element: <SignupPage />,
    },
    {
      path: "/home",
      element: (
        <Auth>
          <HomePage3 />
        </Auth>
      ),
    },
    {
      path: "/terms",
      element: (
        <Auth>
          <TermsPage />
        </Auth>
      ),
    },
    {
      path: "/game/practice",
      element: (
        <Auth>
          <PracticeGamePage />
        </Auth>
      ),
    },
    {
      path: "/game/levels",
      element: (
        <Auth>
          <AuthWithScore>
            <GameLevels />
          </AuthWithScore>
        </Auth>
      ),
    },
    {
      path: "/game/easy",
      element: (
        <Auth>
          <AuthWithScore>
            <EasyGamePage2 />
          </AuthWithScore>
        </Auth>
      ),
    },
    {
      path: "/game/medium",
      element: (
        <Auth>
          <AuthWithScore>
            <MediumGamePage2 />
          </AuthWithScore>
        </Auth>
      ),
    },
    {
      path: "/game/hard",
      element: (
        <Auth>
          <AuthWithScore>
            <HardGamePage2 />
          </AuthWithScore>
        </Auth>
      ),
    },
    {
      path: "/game/winner",
      element: (
        <Auth>
          <WinnerPage />
        </Auth>
      ),
    },
    {
      path: "/game/lose",
      element: (
        <Auth>
          <LosePage />
        </Auth>
      ),
    },
    {
      path: "/user/account",
      element: (
        <Auth>
          <Account />
        </Auth>
      ),
    },
  ]);

  return <RouterProvider router={router}></RouterProvider>;
};

export default App;
