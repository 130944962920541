import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("practice")
  ? JSON.parse(localStorage.getItem("practice"))
  : {
      practiceScore: 4000,
      practiceQuestions:null,
      questionPoints:100
    };

const practiceSlice = createSlice({
  name: "practice",
  initialState,
  reducers: {
    setPracticeQuestions:(state,action)=>{
        state.practiceQuestions=action.payload;
        localStorage.setItem("practice",JSON.stringify(state));
        return state;
    },
    decreasePracticeScore:(state,action)=>{
        state.practiceScore=state.practiceScore-state.questionPoints;
        localStorage.setItem("practice",JSON.stringify(state));
        return state;
    },
    increasePracticeScore:(state,action)=>{
        state.practiceScore=state.practiceScore+state.questionPoints;
        localStorage.setItem("practice",JSON.stringify(state));
        return state;
    },
    resetPracticeState:(state,action)=>{
        state.practiceScore=4000;
        state.practiceQuestions=null;
        state.questionPoints=100;
        localStorage.removeItem("practice");
        return state;
    }
  },
});

export const {
setPracticeQuestions,
resetPracticeState,
increasePracticeScore,
decreasePracticeScore,
} = practiceSlice.actions;
export default practiceSlice;
