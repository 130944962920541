import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("correctWrong")?
JSON.parse(localStorage.getItem("correctWrong")):
{
    correct:null,
    wrong:null,
}

const correctWrongSlice=createSlice({
    name:"correctWrong",
    initialState,
    reducers:{
        storeCorrectIndex:(state,action)=>{
            state.correct=action.payload;
            localStorage.setItem("correctWrong",JSON.stringify(state));
            return state;
        },
        resetCorrectIndex:(state,action)=>{
            state.correct=null;
            localStorage.removeItem("correctWrong");
            return state;
        },
        storeWrongIndex:(state,action)=>{
            state.wrong=action.payload;
            localStorage.setItem("correctWrong",JSON.stringify(state));
            return state;
        },
        resetWrongIndex:(state,action)=>{
            state.wrong=null;
            localStorage.setItem("correctWrong",JSON.stringify(state));
            return state;
        },
    }
})

export const {storeCorrectIndex,resetCorrectIndex,storeWrongIndex,resetWrongIndex}=correctWrongSlice.actions;
export default correctWrongSlice;