import React, { useEffect, useState } from "react";
import classes from "./Account.module.css";
import toast from "react-hot-toast";
import axios from "axios";
import { userInfoApi } from "../Api/api";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import { setUserScore } from "../Slices/userSlice";

const Account = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [score, setScore] = useState("");
  const [questionsPlayed, setQuestionsPlayed] = useState("");
  const [loading, setLoading] = useState(true);
      const dispatch=useDispatch();

  const navigate = useNavigate();
  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      const data = {
        ani: user,
      };
      let headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const res = await axios.post(`${userInfoApi}`, data, {
        headers: headers,
      });
      setScore(res?.data?.Score);
      dispatch(setUserScore(parseInt(res?.data?.Score, 0)));
      setQuestionsPlayed(res?.data?.QuestionPlayed);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.data?.message || error?.message);
    }
  };
  useEffect(() => {
    fetchUserDetails();
  }, []);
  const navigateToBack = () => {
    navigate(-1);
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          <div className={classes.back_icon_container}>
            <div className={classes.back_icon} onClick={navigateToBack}>
              <i className={`fa-solid fa-arrow-left ${classes.arrow}`}></i>
            </div>
            <div className={classes.logo_text}>
              <h3 className={classes.text}>Zero Point</h3>
            </div>
          </div>
          <div className={classes.sub_container}>
            <h3 className={classes.heading}>Account Details</h3>
            <div className={classes.account_container}>
              <div className={classes.avatar_container}>
                <img
                  src="/assets/images/avatar.png"
                  alt="dummy avatar"
                  className={classes.avatar}
                />
                <h3 className={classes.user_text}>{user}</h3>
              </div>

              <div className={classes.score_container}>
                <h3 className={classes.score_text}>Today's Score</h3>
                <div className={classes.score_sub_container}>
                  <img
                    src="/assets/images/pointIcon.png"
                    alt="points"
                    className={classes.score_image}
                  />
                  <p className={classes.score}>{score}</p>
                </div>
              </div>

              <div className={classes.score_container}>
                <h3 className={classes.score_text}>Questions Played Today</h3>
                <div className={classes.score_sub_container}>
                  <img
                    src="/assets/images/question.png"
                    alt="points"
                    className={classes.score_image}
                  />
                  <p className={classes.score}>{questionsPlayed}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Account;
