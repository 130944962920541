import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./MediumGamePage2.module.css";
import { increaseIndex, resetIndex } from "../Slices/indexSlice";
import {
  mediumModeScoreDecrease,
  mediumModeScoreIncrease,
  setUserScore,
} from "../Slices/userSlice";
import { useNavigate } from "react-router-dom";
import {
  DecreaseTime,
  IncreaseTimeForProgress,
  ResetTime,
  ResetTimeForProgress,
  SetProgress,
} from "../Slices/timeSlice";
import Cookies from "js-cookie";
import { motion, useAnimation } from "framer-motion";
import {
  resetCorrectIndex,
  resetWrongIndex,
  storeCorrectIndex,
  storeWrongIndex,
} from "../Slices/correctWrongSlice";
import BackgroundAudio from "../Components/BackgroundAudio";
import CorrectAnswerAudio from "../Components/CorrectAnswerAudio";
import WrongAnswerAudio from "../Components/WrongAnswerAudio";
import Lottie from "lottie-react";
import correctAnimation from "../StaticAnimations/correctAnimation.json";
import axios from "axios";
import { mediumQuestionsApi, saveScoreApi, winnerApi } from "../Api/api";
import toast from "react-hot-toast";
import {
  increaseCorrect,
  increaseWrong,
  resetCorrect,
  resetWrong,
} from "../Slices/totalCorrectWrongSlice";
import { increaseSkip, resetSkip } from "../Slices/skipSlice";
import Stepper from "../Components/Stepper";
import { resetStepper, storeStepper } from "../Slices/stepperSlice";
import {
  resetMediumQuestions,
  storeMediumQuestions,
} from "../Slices/mediumQuestionsSlice";
import Loading from "../Components/Loading";
import { resetEasyQuestions } from "../Slices/easyQuestionsSlice";
import { resetHardQuestions } from "../Slices/hardQuestionsSlice";
import { resetPracticeState } from "../Slices/practiceQuestionsSlice";
import {
  resetOriginalCorrectIndex,
  storeOriginalCorrectIndex,
} from "../Slices/originalCorrectSlice";
import Timer from "../Components/GameComponents/Timer";
import Layout from "../Components/UI/Layout";
import GameMode from "../Components/GameComponents/GameMode";
import SubLayout from "../Components/UI/SubLayout";
import QuizAnimation from "../Components/GameComponents/QuizAnimation";
import QuestionMarkAnimation from "../Components/GameComponents/QuestionMarkAnimation";
import Button3d from "../Components/GameComponents/Button3d";
import OptionBox from "../Components/GameComponents/OptionBox";
import ScoreBox from "../Components/GameComponents/ScoreBox";
import QuestionBox from "../Components/GameComponents/QuestionBox";
import levelupAnimation from "../StaticAnimations/levelupAnimation.json";
import GamePopup from "../Components/GameComponents/GamePopup";

const MediumGamePage2 = () => {
  const [loading, setLoading] = useState(true);
  const [winner, setWinner] = useState(false);
  const [popup, setPopup] = useState(false);
  const [pointerEvents, setPointerEvents] = useState("all");
  const controls = useAnimation(); // Framer Motion controls
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { time, progress } = useSelector((state) => state.timeSlice);
  const { user, score } = useSelector((state) => state.userSlice);
  const { skip } = useSelector((state) => state.skipSlice);
  const { totalCorrect, totalWrong } = useSelector(
    (state) => state.totalCorrectWrongSlice
  );

  const { originalCorrect } = useSelector(
    (state) => state?.originalCorrectSlice
  );
  const { mediumQuestions } = useSelector((state) => state.mediumQuestions);
  const { index } = useSelector((state) => state.indexSlice);

  useEffect(() => {
    if (score == 0) {
      winnerApiHit();
    }
  }, [score]);

  const winnerApiHit = async () => {
    const data = {
      ani: user,
      score: score,
      questionCount: index + 1,
    };
    let headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
    };
    try {
      setWinner(true);
      const res = await axios.post(`${saveScoreApi}`, data, {
        headers: headers,
      });
      dispatch(setUserScore(parseInt(res?.data?.score, 0)));
      try {
        const data2 = {
          ani: user,
          score: 0,
          questionCount: res?.data?.questionCount,
        };
        let headers = {
          Authorization: `Bearer ${Cookies.get("token")}`,
        };
        setWinner(true);
        const response = await axios.post(`${winnerApi}`, data2, {
          headers: headers,
        });
        dispatch(setUserScore(parseInt(0, 0)));
        setTimeout(() => {
          setWinner(false);
          navigate("/game/winner");
        }, 1500);
      } catch (error) {
        setTimeout(() => {
          setWinner(false);
        }, 100);
        toast.error(
          error?.data?.message ||
            error?.data?.response?.message ||
            error?.message
        );
      }
    } catch (error) {
      toast.error(
        error?.data?.message || error?.message || error?.data?.response?.message
      );
    }
  };

  useEffect(() => {
    if (!Cookies.get("token")) {
      navigate("/");
    }
    if (!mediumQuestions) {
      dispatch(resetStepper());
      dispatch(resetEasyQuestions());
      dispatch(resetMediumQuestions());
      dispatch(resetHardQuestions());
      dispatch(resetSkip());
      dispatch(resetCorrect());
      dispatch(resetWrong());
      dispatch(resetCorrectIndex());
      dispatch(resetWrongIndex());
      dispatch(resetIndex());
      dispatch(ResetTime());
      dispatch(ResetTimeForProgress());
      dispatch(resetPracticeState());
      dispatch(resetOriginalCorrectIndex());
      getQuestionsFromBackend();
    } else {
      setLoading(false);
    }
  }, [mediumQuestions, Cookies]);

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, null, document.URL);
    });
  }, [window]);

  const navigateToBack = () => {
    // navigate("/home");
    saveScore("/home");
  };

  const cancelPopupHandler = () => {
    setPopup(false);
    navigate("/home");
  };

  const getQuestionsFromBackend = async () => {
    try {
      setLoading(true);
      let headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const res = await axios.get(`${mediumQuestionsApi}&ani=${user}`, {
        headers: headers,
      });
      console.log(res, "res");
      dispatch(storeMediumQuestions(res?.data?.Medium));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (!mediumQuestions || mediumQuestions?.length <= 0) {
  //     getQuestionsFromBackend();
  //   } else {
  //     setLoading(false);
  //   }
  // }, []);

  useEffect(() => {
    if (totalWrong > 2) {
      saveScore("/game/lose");
    }
  }, [totalWrong]);

  async function saveScore(path, object) {
    const data = {
      ani: user,
      score: score,
      questionCount: index + 1,
    };
    let headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
    };
    try {
      const res = await axios.post(`${saveScoreApi}`, data, {
        headers: headers,
      });
      dispatch(setUserScore(parseInt(res?.data?.score, 0)));
      if (object?.showPopup) {
        dispatch(resetCorrectIndex());
        dispatch(resetWrongIndex());
        dispatch(resetCorrect());
        dispatch(resetWrong());
        setPopup(true);
      } else {
        setTimeout(() => {
          navigate(path);
        }, 1000);
      }
    } catch (error) {
      toast.error(
        error?.data?.message ||
          error?.response?.data?.message ||
          error?.data ||
          error?.message
      );
    }
  }

  useEffect(() => {
    if (!loading && !popup) {
      if (time > 0) {
        const timeoutId = setTimeout(() => {
          dispatch(DecreaseTime());
          dispatch(IncreaseTimeForProgress());
          dispatch(SetProgress());
        }, 1000);

        return () => clearTimeout(timeoutId);
      } else {
        saveScore("/game/lose");
      }
    }
  }, [loading, time, dispatch, navigate]);

  const { correct, wrong } = useSelector((state) => state.correctWrongSlice);

  const checkAnswerAndIncreaseIndex = (data) => {
    if (index >= mediumQuestions.length - 1) {
      if (mediumQuestions[index]?.correctAnswer == data?.option) {
        dispatch(storeStepper({ index: index, correct: true, skip: false }));
        dispatch(storeCorrectIndex(data?.option));
        dispatch(mediumModeScoreDecrease());
        dispatch(increaseCorrect());
        saveScore("/game/hard", { showPopup: true });
      } else {
        dispatch(storeStepper({ index: index, correct: false, skip: false }));
        dispatch(storeWrongIndex(data?.option));
        dispatch(mediumModeScoreIncrease());
        dispatch(increaseWrong());
        dispatch(
          storeOriginalCorrectIndex(mediumQuestions[index]?.correctAnswer)
        );
        if (totalWrong >= 2) {
          saveScore("/game/lose");
        } else {
          saveScore("/game/hard", { showPopup: true });
        }
      }
      // saveScore("/game/hard");
      // saveScore("/game/hard", { showPopup: true });
    } else {
      if (mediumQuestions[index]?.correctAnswer == data?.option) {
        dispatch(storeCorrectIndex(data?.option));
        dispatch(storeStepper({ index: index, correct: true, skip: false }));
        dispatch(mediumModeScoreDecrease());
        dispatch(increaseCorrect());
        setPointerEvents("none");

        setTimeout(() => {
          const animationConfig = {
            opacity: 0,
            transition: { duration: 0.5 },
          };

          controls.start(animationConfig).then(() => {
            dispatch(resetCorrectIndex());
            dispatch(increaseIndex());
            setPointerEvents("all");
            controls.start({ opacity: 1, transition: { duration: 0.5 } });
          });
        }, 500);
      } else {
        dispatch(storeWrongIndex(data?.option));
        dispatch(storeStepper({ index: index, correct: false, skip: false }));
        dispatch(mediumModeScoreIncrease());
        dispatch(increaseWrong());
        dispatch(
          storeOriginalCorrectIndex(mediumQuestions[index]?.correctAnswer)
        );
        setPointerEvents("none");

        setTimeout(() => {
          const animationConfig = {
            opacity: 0,
            transition: { duration: 0.5 },
          };

          if (!(totalWrong > 1)) {
            controls.start(animationConfig).then(() => {
              dispatch(resetWrongIndex());
              dispatch(increaseIndex());
              dispatch(resetOriginalCorrectIndex());
              setPointerEvents("all");
              controls.start({ opacity: 1, transition: { duration: 0.5 } });
            });
          }
        }, 500);
      }
    }
  };

  const skipHandler = () => {
    if (index >= mediumQuestions.length - 1) {
      dispatch(increaseSkip());
      dispatch(mediumModeScoreIncrease());
      dispatch(increaseWrong());
      // saveScore("/game/hard");
      // saveScore("/game/hard", { showPopup: true });
      if (totalWrong >= 2) {
        saveScore("/game/lose");
      } else {
        saveScore("/game/hard", { showPopup: true });
      }
    } else {
      dispatch(mediumModeScoreIncrease());
      dispatch(increaseWrong());
      dispatch(increaseSkip());
      dispatch(storeStepper({ index: index, correct: null, skip: true }));
      dispatch(
        storeOriginalCorrectIndex(mediumQuestions[index]?.correctAnswer)
      );
      setTimeout(() => {
        const animationConfig = {
          opacity: 0,
          transition: { duration: 0.2 },
        };

        if (!(totalWrong > 1)) {
          controls.start(animationConfig).then(() => {
            dispatch(increaseIndex());
            dispatch(resetOriginalCorrectIndex());
            controls.start({ opacity: 1, transition: { duration: 0.2 } });
          });
        }
      }, 500);
    }
  };

  const playAgainHandlerForPopup = () => {
    setPopup(false);
    getQuestionsFromBackend();
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : winner ? (
        <div className={classes.container} style={{ height: "100dvh" }}>
          <div
            className={classes.sub_container}
            style={{
              height: "90dvh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <div className={classes.levelupAnimationContainer}>
              <Lottie
                animationData={levelupAnimation}
                loop={true}
                autoplay={true}
                className={classes.levelupAnimation}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <BackgroundAudio />
          {correct && <CorrectAnswerAudio />}
          {wrong && <WrongAnswerAudio />}
          <Layout>
            <Timer progress={progress} time={time} />

            <GameMode mode="Medium Mode" navigateToBack={navigateToBack} />

            <Stepper index={index} />

            <SubLayout>
              <QuizAnimation />

              <div className={classes.questions_options_container}>
                <QuestionMarkAnimation />

                <div className={classes.skip_button}>
                  <Button3d text="Skip" skipHandler={skipHandler} />
                </div>

                <motion.div
                  initial={{ opacity: 1 }}
                  animate={controls}
                  className={classes.questions_options_sub_container}
                  style={{ pointerEvents: pointerEvents }}
                >
                  <QuestionBox question={mediumQuestions[index]?.question} />
                  <div className={classes.options_box}>
                    <OptionBox
                      originalCorrect={originalCorrect}
                      wrong={wrong}
                      originalCorrectValue="1"
                      wrongValue="1"
                      optionIndex={{ option: "1" }}
                      option={mediumQuestions[index]?.optionA}
                      checkAnswerAndIncreaseIndex={checkAnswerAndIncreaseIndex}
                    />

                    <OptionBox
                      originalCorrect={originalCorrect}
                      wrong={wrong}
                      originalCorrectValue="2"
                      wrongValue="2"
                      optionIndex={{ option: "2" }}
                      option={mediumQuestions[index]?.optionB}
                      checkAnswerAndIncreaseIndex={checkAnswerAndIncreaseIndex}
                    />

                    <OptionBox
                      originalCorrect={originalCorrect}
                      wrong={wrong}
                      originalCorrectValue="3"
                      wrongValue="3"
                      optionIndex={{ option: "3" }}
                      option={mediumQuestions[index]?.optionC}
                      checkAnswerAndIncreaseIndex={checkAnswerAndIncreaseIndex}
                    />

                    <OptionBox
                      originalCorrect={originalCorrect}
                      wrong={wrong}
                      originalCorrectValue="4"
                      wrongValue="4"
                      optionIndex={{ option: "4" }}
                      option={mediumQuestions[index]?.optionD}
                      checkAnswerAndIncreaseIndex={checkAnswerAndIncreaseIndex}
                    />
                  </div>

                  <ScoreBox score={score} />

                  {correct && (
                    <div className={classes.overlapContainer}>
                      <div className={classes.overlapAnimationContainer}>
                        <Lottie
                          animationData={correct && correctAnimation}
                          loop={true}
                          autoplay={true}
                          className={classes.overlapAnimation}
                        />
                      </div>
                    </div>
                  )}
                </motion.div>
              </div>
              {popup && (
                <GamePopup
                  playAgainNavigation="/game/medium"
                  mode1="Easy Mode"
                  mode1Navigation="/game/easy"
                  mode2="Hard Mode"
                  mode2Navigation="/game/hard"
                  cancelHandler={cancelPopupHandler}
                  playAgainHandler={playAgainHandlerForPopup}
                />
              )}
            </SubLayout>
          </Layout>
        </>
      )}
    </>
  );
};

export default MediumGamePage2;
