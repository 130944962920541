import React, { useState, useEffect } from "react";
import classes from "./GameLevels.module.css";
import Lottie from "lottie-react";
import easyModeAnimation from "../StaticAnimations/easyModeAnimation.json";
import mediumModeAnimation from "../StaticAnimations/mediumModeAnimation.json";
import hardModeAnimation from "../StaticAnimations/hardModeAnimation.json";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { modesApi } from "../Api/api";
import Loading from "../Components/Loading";
import { useDispatch } from "react-redux";
import {
  setEasyModePoints,
  setHardModePoints,
  setMediumModePoints,
} from "../Slices/userSlice";
import { resetStepper } from "../Slices/stepperSlice";
import { resetSkip } from "../Slices/skipSlice";
import { resetCorrect, resetWrong } from "../Slices/totalCorrectWrongSlice";
import { resetIndex } from "../Slices/indexSlice";
import {
  resetCorrectIndex,
  resetWrongIndex,
} from "../Slices/correctWrongSlice";
import { ResetTime, ResetTimeForProgress } from "../Slices/timeSlice";
import { resetPracticeState } from "../Slices/practiceQuestionsSlice";
import { resetOriginalCorrectIndex } from "../Slices/originalCorrectSlice";
import { resetEasyQuestions } from "../Slices/easyQuestionsSlice";
import { resetMediumQuestions } from "../Slices/mediumQuestionsSlice";
import { resetHardQuestions } from "../Slices/hardQuestionsSlice";

const GameLevels = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToBack = () => {
    navigate("/home");
  };
  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      let headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const res = await axios.get(`${modesApi}`, { headers: headers });
      console.log(res?.data?.points[0]);
      dispatch(setEasyModePoints(res?.data?.points[0]?.points));
      dispatch(setMediumModePoints(res?.data?.points[1]?.points));
      dispatch(setHardModePoints(res?.data?.points[2]?.points));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.data?.message || err?.message || err);
    }
  };
  useEffect(() => {
    fetchDataFromBackend();
  }, []);

  const handleNavigate = (path) => {
    dispatch(resetStepper());
    dispatch(resetEasyQuestions());
    dispatch(resetMediumQuestions());
    dispatch(resetHardQuestions());
    dispatch(resetSkip());
    dispatch(resetCorrect());
    dispatch(resetWrong());
    dispatch(resetCorrectIndex());
    dispatch(resetWrongIndex());
    dispatch(resetIndex());
    dispatch(ResetTime());
    dispatch(ResetTimeForProgress());
    dispatch(resetPracticeState());
    dispatch(resetOriginalCorrectIndex());
    navigate(path);
  };

  return (
    <div className={classes.container}>
      <div className={classes.back_icon_container}>
        <div className={classes.back_icon} onClick={navigateToBack}>
          <i className={`fa-solid fa-arrow-left ${classes.arrow}`}></i>
        </div>
        <div className={classes.logo_text}>
          <h3 className={classes.text}>Zero Point</h3>
        </div>
      </div>
      <div className={classes.sub_container}>
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.game_levels_container}>
            <div className={classes.game_levels_subcontainer}>
              <div
                className={classes.game_level_easy}
                onClick={() => handleNavigate("/game/easy")}
              >
                <h3 className={classes.easy_text}>Easy</h3>
                <div className={classes.animationContainer}>
                  <Lottie
                    animationData={easyModeAnimation}
                    loop={true}
                    autoplay={true}
                    className={classes.animation}
                  />
                </div>
              </div>

              <div
                className={classes.game_level_medium}
                onClick={() => handleNavigate("/game/medium")}
              >
                <h3 className={classes.medium_text}>Medium</h3>
                <div className={classes.animationContainer}>
                  <Lottie
                    animationData={mediumModeAnimation}
                    loop={true}
                    autoplay={true}
                    className={classes.animation}
                  />
                </div>
              </div>

              <div
                className={classes.game_level_hard}
                onClick={() => handleNavigate("/game/hard")}
              >
                <h3 className={classes.hard_text}>Hard</h3>
                <div className={classes.animationContainer}>
                  <Lottie
                    animationData={hardModeAnimation}
                    loop={true}
                    autoplay={true}
                    className={classes.animation}
                  />
                </div>
              </div>

              <div
                className={classes.game_level_practice}
                onClick={() => handleNavigate("/game/practice")}
              >
                <h3 className={classes.practice_text}>Practice</h3>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameLevels;
