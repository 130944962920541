import {createSlice} from "@reduxjs/toolkit";

const initialState={
    mobileMenu:false,
}

const mobileMenuSlice=createSlice({
    name:'mobileMenu',
    initialState,
    reducers:{
        openMobileMenu:(state,action)=>{
            state.mobileMenu=true;
            return state;
        },
        closeMobileMenu:(state,action)=>{
            state.mobileMenu=false;
            return state;
        }
    }
})

export const {openMobileMenu,closeMobileMenu}=mobileMenuSlice.actions;
export default mobileMenuSlice;