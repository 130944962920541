import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("originalCorrect")?
JSON.parse(localStorage.getItem("originalCorrect")):
{
    originalCorrect:null,
}

const originalCorrectSlice=createSlice({
    name:"originalCorrect",
    initialState,
    reducers:{
        storeOriginalCorrectIndex:(state,action)=>{
            state.originalCorrect=action.payload;
            localStorage.setItem("originalCorrect",JSON.stringify(state));
            return state;
        },
        resetOriginalCorrectIndex:(state,action)=>{
            state.originalCorrect=null;
            localStorage.removeItem("originalCorrect");
            return state;
        },
    }
})

export const {storeOriginalCorrectIndex,resetOriginalCorrectIndex}=originalCorrectSlice.actions;
export default originalCorrectSlice;