import React from "react";
import classes from "./OptionBox.module.css";

const OptionBox = ({originalCorrect,wrong,originalCorrectValue,wrongValue,checkAnswerAndIncreaseIndex,optionIndex,option}) => {
    const checkAnswerAndIncreaseIndexHandler=(data)=>{
        checkAnswerAndIncreaseIndex(data);
    }
  return (
    <div
      className={
        originalCorrect === originalCorrectValue
          ? `${classes.option_box} ${classes.correct_option_box}`
          : wrong === wrongValue
          ? `${classes.option_box} ${classes.wrong_option_box}`
          : classes.option_box
      }
      onClick={() => checkAnswerAndIncreaseIndexHandler(optionIndex)}
    >
      <h3 className="text-3xl text-center text-slate-100 font-semibold tracking-wider">
        {option}{" "}
      </h3>
      {wrong === wrongValue && (
        <i className={`${classes.icon} fa-solid fa-circle-xmark`}></i>
      )}
    </div>
  );
};

export default OptionBox;
