import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("index")
  ? JSON.parse(localStorage.getItem("index"))
  : {
      index: 0,
    };

const indexSlice = createSlice({
  name: "index",
  initialState,
  reducers: {
    increaseIndex: (state, action) => {
      state.index = state.index + 1;
      localStorage.setItem("index", JSON.stringify(state));
      return state;
    },
    resetIndex: (state, action) => {
      state.index = 0;
      localStorage.setItem("index", JSON.stringify(state));
      return state;
    },
  },
});

export const { increaseIndex, resetIndex } = indexSlice.actions;
export default indexSlice;