import React from "react";
import classes from "./Stepper.module.css";
import { useSelector } from "react-redux";

const Stepper = ({ index }) => {
  const { stepper } = useSelector((state) => state.stepperSlice);
  return (
    <div className={classes.container}>
      <div className={classes.sub_container}>
        <div className={classes.circle_container}>
          <div
            className={`${
              index >= 0
                ? classes.circle_with_line_active
                : classes.circle_with_line
            }`}
          >
            <div className={classes.circle}>
              {stepper.length > 0 && stepper[0]?.correct ? (
                <i className={`fa-solid fa-check ${classes.icon}`}></i>
              ) : stepper[0]?.skip ? (
                <i class={`fa-solid fa-forward ${classes.icon}`}></i>
              ) : stepper[0]?.correct == false ? (
                <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
              ) : null}
            </div>
            <div
              className={`${index >= 0 ? classes.line_active : classes.line}`}
            ></div>
          </div>
          <h2>1</h2>
        </div>
        <div className={classes.circle_container}>
          <div
            className={`${
              index >= 1
                ? classes.circle_with_line_active
                : classes.circle_with_line
            }`}
          >
            <div className={classes.circle}>
              {stepper.length > 0 && stepper[1]?.correct ? (
                <i className={`fa-solid fa-check ${classes.icon}`}></i>
              ) : stepper[1]?.skip ? (
                <i class={`fa-solid fa-forward ${classes.icon}`}></i>
              ) : stepper[1]?.correct == false ? (
                <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
              ) : null}
            </div>
            <div
              className={`${index >= 1 ? classes.line_active : classes.line}`}
            ></div>
          </div>
          <h2>2</h2>
        </div>
        <div className={classes.circle_container}>
          <div
            className={`${
              index >= 2
                ? classes.circle_with_line_active
                : classes.circle_with_line
            }`}
          >
            <div className={classes.circle}>
              {stepper.length > 0 && stepper[2]?.correct ? (
                <i className={`fa-solid fa-check ${classes.icon}`}></i>
              ) : stepper[2]?.skip ? (
                <i class={`fa-solid fa-forward ${classes.icon}`}></i>
              ) : stepper[2]?.correct == false ? (
                <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
              ) : null}
            </div>
            <div
              className={`${index >= 2 ? classes.line_active : classes.line}`}
            ></div>
          </div>
          <h2>3</h2>
        </div>
        <div className={classes.circle_container}>
          <div
            className={`${
              index >= 3
                ? classes.circle_with_line_active
                : classes.circle_with_line
            }`}
          >
            <div className={classes.circle}>
              {stepper.length > 0 && stepper[3]?.correct ? (
                <i className={`fa-solid fa-check ${classes.icon}`}></i>
              ) : stepper[3]?.skip ? (
                <i class={`fa-solid fa-forward ${classes.icon}`}></i>
              ) : stepper[3]?.correct == false ? (
                <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
              ) : null}
            </div>
            <div
              className={`${index >= 3 ? classes.line_active : classes.line}`}
            ></div>
          </div>
          <h2>4</h2>
        </div>
        <div className={classes.circle_container}>
          <div
            className={`${
              index >= 4
                ? classes.circle_with_line_active
                : classes.circle_with_line
            }`}
          >
            <div className={classes.circle}>
              {stepper.length > 0 && stepper[4]?.correct ? (
                <i className={`fa-solid fa-check ${classes.icon}`}></i>
              ) : stepper[4]?.skip ? (
                <i class={`fa-solid fa-forward ${classes.icon}`}></i>
              ) : stepper[4]?.correct == false ? (
                <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
              ) : null}
            </div>
            <div
              className={`${index >= 4 ? classes.line_active : classes.line}`}
            ></div>
          </div>
          <h2>5</h2>
        </div>
        <div className={classes.circle_container}>
          <div
            className={`${
              index >= 5
                ? classes.circle_with_line_active
                : classes.circle_with_line
            }`}
          >
            <div className={classes.circle}>
              {stepper.length > 0 && stepper[5]?.correct ? (
                <i className={`fa-solid fa-check ${classes.icon}`}></i>
              ) : stepper[5]?.skip ? (
                <i class={`fa-solid fa-forward ${classes.icon}`}></i>
              ) : stepper[5]?.correct == false ? (
                <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
              ) : null}
            </div>
            <div
              className={`${index >= 5 ? classes.line_active : classes.line}`}
            ></div>
          </div>
          <h2>6</h2>
        </div>
        <div className={classes.circle_container}>
          <div
            className={`${
              index >= 6
                ? classes.circle_with_line_active
                : classes.circle_with_line
            }`}
          >
            <div className={classes.circle}>
              {stepper.length >= 0 && stepper[6]?.correct ? (
                <i className={`fa-solid fa-check ${classes.icon}`}></i>
              ) : stepper[6]?.skip ? (
                <i class={`fa-solid fa-forward ${classes.icon}`}></i>
              ) : stepper[6]?.correct == false ? (
                <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
              ) : null}
            </div>
            <div
              className={`${index >= 6 ? classes.line_active : classes.line}`}
            ></div>
          </div>
          <h2>7</h2>
        </div>
        <div className={classes.circle_container}>
          <div
            className={`${
              index >= 7
                ? classes.circle_with_line_active
                : classes.circle_with_line
            }`}
          >
            <div className={classes.circle}>
            {stepper.length >= 0 && stepper[7]?.correct ? (
                <i className={`fa-solid fa-check ${classes.icon}`}></i>
              ) : stepper[7]?.skip ? (
                <i class={`fa-solid fa-forward ${classes.icon}`}></i>
              ) : stepper[7]?.correct == false ? (
                <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
              ) : null}
            </div>
            <div
              className={`${index >= 7 ? classes.line_active : classes.line}`}
            ></div>
          </div>
          <h2>8</h2>
        </div>
        <div className={classes.circle_container}>
          <div
            className={`${
              index >= 8
                ? classes.circle_with_line_active
                : classes.circle_with_line
            }`}
          >
            <div className={classes.circle}>
            {stepper.length >= 0 && stepper[8]?.correct ? (
                <i className={`fa-solid fa-check ${classes.icon}`}></i>
              ) : stepper[8]?.skip ? (
                <i class={`fa-solid fa-forward ${classes.icon}`}></i>
              ) : stepper[8]?.correct == false ? (
                <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
              ) : null}
            </div>
            <div
              className={`${index >= 8 ? classes.line_active : classes.line}`}
            ></div>
          </div>
          <h2>9</h2>
        </div>
        <div className={classes.circle_container}>
          <div
            className={`${
              index >= 9
                ? classes.circle_with_line_active
                : classes.circle_with_line
            }`}
          >
            <div className={classes.circle}>
            {stepper.length >= 0 && stepper[9]?.correct ? (
                <i className={`fa-solid fa-check ${classes.icon}`}></i>
              ) : stepper[9]?.skip ? (
                <i class={`fa-solid fa-forward ${classes.icon}`}></i>
              ) : stepper[9]?.correct == false ? (
                <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
              ) : null}
            </div>
          </div>
          <h2 style={{ margin: 0 }}>10</h2>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
