import React from "react";

const CorrectAnswerAudio = () => {
  return (
    <audio id="backgroundMusic" autoPlay>
      <source src="/assets/music/correct.mp3" type="audio/mp3" />
    </audio>
  );
};

export default CorrectAnswerAudio;