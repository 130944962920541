import React from "react";
import classes from "./QuestionBox.module.css";

const QuestionBox = ({ question }) => {
  return (
    <div className={classes.question_box}>
      <h4 className={classes.question_text}>{question}</h4>
    </div>
  );
};

export default QuestionBox;
