import React from "react";

const BackgroundAudio = () => {
  return (
    <audio id="backgroundMusic" autoPlay loop>
      <source src="/assets/music/backgroundMusic.mp3" type="audio/mp3" />
    </audio>
  );
};

export default BackgroundAudio;