import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import animationData from "../StaticAnimations/winnerAnimation.json";
// import classes from "./LosePage.module.css";
import classes from "./WinnerPage.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetStepper } from "../Slices/stepperSlice";
import { resetSkip } from "../Slices/skipSlice";
import { resetCorrect, resetWrong } from "../Slices/totalCorrectWrongSlice";
import { resetIndex } from "../Slices/indexSlice";
import {
  resetCorrectIndex,
  resetWrongIndex,
} from "../Slices/correctWrongSlice";
import { resetPracticeState } from "../Slices/practiceQuestionsSlice";
import { setUserScore } from "../Slices/userSlice";
import Cookies from "js-cookie";
import { userInfoApi } from "../Api/api";
import axios from "axios";
import toast from "react-hot-toast";
import Loading from "../Components/Loading";

const WinnerPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const [score, setScore] = useState("");
  const [questionsPlayed, setQuestionsPlayed] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      const data = {
        ani: user,
      };
      let headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const res = await axios.post(`${userInfoApi}`, data, {
        headers: headers,
      });
      setScore(res?.data?.Score);
      dispatch(setUserScore(parseInt(res?.data?.Score, 0)));
      setQuestionsPlayed(res?.data?.QuestionPlayed);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.data?.message || error?.message);
    }
  };
  useEffect(() => {
    dispatch(resetStepper());
    dispatch(resetSkip());
    dispatch(resetCorrect());
    dispatch(resetWrong());
    dispatch(resetIndex());
    dispatch(resetCorrectIndex());
    dispatch(resetWrongIndex());
    dispatch(resetPracticeState());
    fetchUserDetails();
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          <div className={classes.sub_container}>
            <div className={classes.animation_container}>
              <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
                className={classes.loseAnimation}
              />
            </div>
            <div className={classes.text_container}>
              <h2 className={classes.text}>Congratulations</h2>
              <p className={classes.paragraph}>
                {" "}
                You have reached Zero Point...
              </p>
            </div>

            <div className={classes.score_container}>
              <h3 className={classes.score_text}>Score</h3>
              <div className={classes.score_sub_container}>
                <img
                  src="/assets/images/pointIcon.png"
                  alt="points"
                  className={classes.score_image}
                />
                <p className={classes.score}>{score}</p>
              </div>
            </div>

            <div className={classes.score_container}>
              <h3 className={classes.score_text}>Questions Played</h3>
              <div className={classes.score_sub_container}>
                <img
                  src="/assets/images/question.png"
                  alt="points"
                  className={classes.score_image}
                />
                <p className={classes.score}>{questionsPlayed}</p>
              </div>
            </div>

            <div className={classes.btn_container}>
              <div className={classes.back_btn}>
                <button type="button" onClick={() => navigate("/home")}>
                  <span className={classes.shadow}></span>
                  <span className={classes.edge}></span>
                  <span className={`${classes.front} ${classes.text}`}>
                    <i className={`fa-solid fa-arrow-left ${classes.icon}`}></i>
                    Go Back
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WinnerPage;
