import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : {
      user: null,
      score: null,
      easyModePoints: null,
      mediumModePoints: null,
      hardModePoints: null,
    };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserCredentials: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    removeUserCredentials: (state, action) => {
      state.user = null;
      localStorage.removeItem("user");
      return state;
    },
    setUserScore: (state, action) => {
      state.score = action.payload;
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    setEasyModePoints: (state, action) => {
      state.easyModePoints = parseInt(action.payload);
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    setMediumModePoints: (state, action) => {
      state.mediumModePoints = parseInt(action.payload);
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    setHardModePoints: (state, action) => {
      state.hardModePoints = parseInt(action.payload);
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    easyModeScoreDecrease: (state, action) => {
      if(state.score < state.easyModePoints){
        state.score=0;
      }
      else{
        state.score = state.score - state.easyModePoints;
      }
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    easyModeScoreIncrease: (state, action) => {
      state.score = state.score + state.easyModePoints;
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    mediumModeScoreDecrease: (state, action) => {
      state.score = state.score - state.mediumModePoints;
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    mediumModeScoreIncrease: (state, action) => {
      if(state.score < state.mediumModePoints){
        state.score=0;
      }
      else{
        state.score = state.score + state.mediumModePoints;
      }
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    hardModeScoreDecrease: (state, action) => {
      if(state.score < state.hardModePoints){
        state.score=0;
      }
      else{
        state.score = state.score - state.hardModePoints;
      }
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
    hardModeScoreIncrease: (state, action) => {
      state.score = state.score + state.hardModePoints;
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
  },
});

export const {
  setUserCredentials,
  removeUserCredentials,
  easyModeScoreDecrease,
  easyModeScoreIncrease,
  mediumModeScoreDecrease,
  mediumModeScoreIncrease,
  hardModeScoreDecrease,
  hardModeScoreIncrease,
  setEasyModePoints,
  setMediumModePoints,
  setHardModePoints,
  setUserScore,
} = userSlice.actions;
export default userSlice;
