import React, { useState } from "react";
import classes from "./SignupPage.module.css";
import axios from "axios";
import { registerApi } from "../Api/api";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserCredentials, setUserScore } from "../Slices/userSlice";
import { resetIndex } from "../Slices/indexSlice";
import toast from "react-hot-toast";
import Loader from "../Components/Loader";
import Cookies from "js-cookie";

const SignupPage = () => {
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = async () => {
    if (number.trim().length == 0 || password.trim().length == 0) {
      toast.error("Input fields cannot be empty!");
    } else {
      // Hit the api
      try {
        setLoading(true);
        const data = { ani: number, password };
        const res = await axios.post(`${registerApi}`, data);
        if (!res?.data?.error) {
          Cookies.set('token', res?.data?.token, { expires: 2 });
          dispatch(setUserCredentials(number));
          dispatch(setUserScore(parseInt(res?.data?.score, 0)));
          dispatch(resetIndex());
          setLoading(false);
          navigate("/home");
        } else {
          setLoading(false);
          toast.error("Number already registered!");
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          error?.data?.message ||
            error?.message ||
            error?.response?.data?.message
        );
      }
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.form_wrapper}>
        <img src="/assets/images/img2.png" className={classes.img_right} />
        <div className={classes.form_wrapper_right}>
          <h1>Welcome to Zero Points</h1>
          <form className={classes.form_signup}>
            <div className={classes.input_group}>
              <input
                type="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Number"
              />
              <i className="fa-solid fa-user"></i>
            </div>
            <div className={classes.input_group}>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <i className="fa-solid fa-lock"></i>
            </div>
            <button
              className={classes.form_btn}
              onClick={submitHandler}
              type="button"
            >
              Sign Up
            </button>
            {loading && <Loader />}
            <div className={classes.form_link}  onClick={()=>navigate("/")}>
              <Link to="/"> Login In , if you are already a user!</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
