import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("skip")?
JSON.parse(localStorage.getItem("skip")):
{
    skip:0,
}

const skipSlice=createSlice({
    name:"skip",
    initialState,
    reducers:{
        increaseSkip:(state,action)=>{
            state.skip=state.skip+1;
            localStorage.setItem("skip",JSON.stringify(state));
            return state;
        },
        resetSkip:(state,action)=>{
            state.skip=0;
            localStorage.removeItem("skip");
            return state;
        },
    }
})

export const {increaseSkip,resetSkip}=skipSlice.actions;
export default skipSlice;