import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("easyQuestions")?
JSON.parse(localStorage.getItem("easyQuestions")):
{
    easyQuestions:null
}

const easyQuestionsSlice=createSlice({
    name:"easyQuestions",
    initialState,
    reducers:{
        storeEasyQuestions:(state,action)=>{
            state.easyQuestions=action.payload;
            localStorage.setItem("easyQuestions",JSON.stringify(state));
            return state;
        },
        resetEasyQuestions:(state,action)=>{
            state.easyQuestions=null;
            localStorage.removeItem("easyQuestions");
            return state;
        }
    }
})

export const {storeEasyQuestions,resetEasyQuestions}=easyQuestionsSlice.actions;
export default easyQuestionsSlice;