import React from 'react';
import Lottie from "lottie-react";
import questionMarkAnimation from "../../StaticAnimations/questionMarkAnimation.json";
import classes from "./QuestionMarkAnimation.module.css";

const QuestionMarkAnimation = () => {
  return (
    <div className={classes.questionMarkContainer}>
    <Lottie
      animationData={questionMarkAnimation}
      loop={true}
      autoplay={true}
      className={classes.questionMarkAnimation}
    />
  </div>
  )
}

export default QuestionMarkAnimation;