import React, { useEffect, useRef } from "react";
import classes from "./MobileDrawer.module.css";
import { motion, useAnimation } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { closeMobileMenu } from "../Slices/mobileMenuSlice";
import { Link } from "react-router-dom";

const MobileDrawer = () => {
  const { mobileMenu } = useSelector((state) => state.mobileMenuSlice);
  const dispatch = useDispatch();
  const mainControls = useAnimation();
  const variants = {
    hidden: { opacity: 0, x: 800 },
    visible: { opacity: 1, x: 0 },
  };

  useEffect(() => {
    if (mobileMenu) {
      mainControls.start(variants.visible);
    } else {
      mainControls.start(variants.hidden);
    }
  }, [mobileMenu]);

  const closeMobileMenuHandler = () => {
    dispatch(closeMobileMenu());
  };

  return (
    <motion.main
      variants={variants}
      initial={variants.hidden}
      animate={mainControls}
      transition={{ duration: 0.6, delay: 0.2 }}
      className={classes.drawer}
      onClick={closeMobileMenuHandler}
    >
      <div className={classes.container}>
        <div className={classes.menu_list}>
          <a
            href="#how_to_play_section"
            className={classes.menu}
            onClick={closeMobileMenuHandler}
          >
            How to play?
          </a>
          <a
            href="#leaderboard_section"
            className={classes.menu}
            onClick={closeMobileMenuHandler}
          >
            Leaderboard
          </a>
          <a
            href="#terms_and_conditions_section"
            onClick={closeMobileMenuHandler}
            className={classes.menu}
          >
            Terms & Conditions
          </a>
          <Link to="/user/account" onClick={closeMobileMenuHandler} className={classes.menu}>
            Account
          </Link>
        </div>
        <div className={classes.close_icon} onClick={closeMobileMenuHandler}>
          <i className={`fa-solid fa-xmark ${classes.icon}`}></i>
        </div>
      </div>
    </motion.main>
  );
};

export default MobileDrawer;
