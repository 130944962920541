import React, { useEffect, useState } from "react";
import classes from "./LoginPage.module.css";
import axios from "axios";
import { loginApi } from "../Api/api";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserCredentials, setUserScore } from "../Slices/userSlice";
import { resetIndex } from "../Slices/indexSlice";
import toast from "react-hot-toast";
import Loader from "../Components/Loader";
import Cookies from "js-cookie";

const LoginPage = () => {
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (Cookies.get("token")) {
      navigate("/home");
    }
  }, [Cookies]);

  const setCookie = (cookieName, cookieValue) => {
    const currentDate = new Date();

    // Set expiration to 12 AM tomorrow
    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(0, 0, 0, 0);

    const expiresUTC = currentDate.toUTCString();

    Cookies.set(cookieName, cookieValue, { expires: new Date(expiresUTC) });
  };

  const submitHandler = async () => {
    if (number.trim().length == 0 || password.trim().length == 0) {
      toast.error("Input fields cannot be empty!");
    } else {
      // Hit the api
      try {
        setLoading(true);
        const data = { ani: number, password };
        const res = await axios.post(`${loginApi}`, data);
        console.log(res, "res");
        if (!res?.data?.error) {
          setCookie("token", res?.data?.token);
          dispatch(setUserCredentials(number));
          dispatch(setUserScore(parseInt(res?.data?.score, 0)));
          dispatch(resetIndex());
          setLoading(false);
          navigate("/home");
        } else {
          setLoading(false);
          toast.error("Wrong Credentials!");
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          error?.data?.message ||
            error?.message ||
            error?.response?.data?.message
        );
      }
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.form_wrapper}>
        <img src="/assets/images/img1.png" className={`${classes.img_left} wow fadeInUp`} />
        <div className={classes.form_wrapper_left}>
          <h1 className="wow fadeInDown">Welcome to Zero Points</h1>
          <form className={`${classes.form_signup} wow fadeInDown`}>
            <div className={classes.input_group}>
              <input
                type="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Number"
              />
              <i className="fa-solid fa-user"></i>
            </div>
            <div className={classes.input_group}>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <i className="fa-solid fa-lock"></i>
            </div>
            <button
              className={classes.form_btn}
              onClick={submitHandler}
              type="button"
            >
              Login
            </button>
            {loading && <Loader />}
            <div
              className={classes.form_link}
              onClick={() => navigate("/signup")}
            >
              <Link to="/signup"> Sign up , if you are not a User</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
