import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("hardQuestions")?
JSON.parse(localStorage.getItem("hardQuestions")):
{
    hardQuestions:null
}

const hardQuestionsSlice=createSlice({
    name:"hardQuestions",
    initialState,
    reducers:{
        storeHardQuestions:(state,action)=>{
            state.hardQuestions=action.payload;
            localStorage.setItem("hardQuestions",JSON.stringify(state));
            return state;
        },
        resetHardQuestions:(state,action)=>{
            state.hardQuestions=null;
            localStorage.removeItem("hardQuestions");
            return state;
        }
    }
})

export const {storeHardQuestions,resetHardQuestions}=hardQuestionsSlice.actions;
export default hardQuestionsSlice;