import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("mediumQuestions")?
JSON.parse(localStorage.getItem("mediumQuestions")):
{
    mediumQuestions:null
}

const mediumQuestionsSlice=createSlice({
    name:"mediumQuestions",
    initialState,
    reducers:{
        storeMediumQuestions:(state,action)=>{
            state.mediumQuestions=action.payload;
            localStorage.setItem("mediumQuestions",JSON.stringify(state));
            return state;
        },
        resetMediumQuestions:(state,action)=>{
            state.mediumQuestions=null;
            localStorage.removeItem("mediumQuestions");
            return state;
        }
    }
})

export const {storeMediumQuestions,resetMediumQuestions}=mediumQuestionsSlice.actions;
export default mediumQuestionsSlice;