import React from "react";
import classes from "./GameMode.module.css";

const GameMode = ({mode,navigateToBack}) => {
  const navigateToBackHandler=()=>{
    navigateToBack();
  }
  return (
    <div className={classes.text_container}>
      <div className={classes.back_btn} onClick={navigateToBackHandler}>
        <i className={`fa-solid fa-arrow-left ${classes.back_icon}`}></i>
      </div>
      <h3 className={classes.text}>{mode}</h3>
    </div>
  );
};

export default GameMode;
