import React, { useEffect, useRef, useState } from "react";
import classes from "./InitialQuestionModal.module.css";
import { FaCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../Slices/initialQuestionModalSlice";
import Loader from "./Loader";
import Cookies from "js-cookie";

import correctAnimation from "../StaticAnimations/correctAnimation.json";
import wrongAnimation from "../StaticAnimations/wrongAnimation.json";
import Lottie from "lottie-react";
import toast from "react-hot-toast";
import axios from "axios";
import { setUserScore } from "../Slices/userSlice";
import { saveScoreApi } from "../Api/api";

const InitialQuestionModal = ({ open, modalQuestion }) => {
  const [answer, setAnswer] = useState("");
  const [correct, setCorrect] = useState(null);
  const [wrong, setWrong] = useState(null);
  const dispatch = useDispatch();
  const modalRef = useRef();

  const { score, user } = useSelector((state) => state.userSlice);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(closeModal());
        Cookies.set("donotShow", true, { expires: 1 });
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dispatch]);

  const saveScore = async (dataScore) => {
    const data = {
      ani: user,
      score: dataScore,
      questionCount: 1,
    };
    let headers = {
      Authorization: `Bearer ${Cookies.get("token")}`,
    };
    try {
      const res = await axios.post(`${saveScoreApi}`, data, {
        headers: headers,
      });
      dispatch(setUserScore(parseInt(res?.data?.score, 0)));
    } catch (error) {
      toast.error(
        error?.data?.message || error?.message || error?.response?.data?.message
      );
    }
  };

  const submitHandler = (event) => {
    event.stopPropagation();
    if (answer.toLowerCase() == modalQuestion?.correctAnswer) {
      setCorrect(true);
      let newScore=score-200;
      saveScore(newScore);
    } else {
      setWrong(true);
      let newScore=score+200;
      saveScore(newScore);
    }

    setTimeout(() => {
      dispatch(closeModal());
    }, 1000);
  };

  return (
    <div
      ref={modalRef}
      className={`${classes.modal_position} ${open && classes.modal_show}`}
    >
      {
        !modalQuestion ? (
          <Loader />
        ) : (
          <>
            {correct || wrong ? (
              <div className={classes.overlapContainer}>
                <div className={classes.overlapAnimationContainer}>
                  <Lottie
                    animationData={correct ? correctAnimation : wrongAnimation}
                    loop={true}
                    autoplay={true}
                    className={classes.overlapAnimation}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className={classes.text_container}>
                  <h2>Question of The Day</h2>
                </div>

                <div className={classes.box}>
                  <div className={classes.underline}>
                    <div className={classes.icons_container}>
                      <FaCircle style={{ color: "yellow" }} />
                      <FaCircle style={{ color: "green" }} />
                      <FaCircle style={{ color: "red" }} />
                    </div>
                  </div>

                  <div className={classes.box_question}>
                    <h4 className={classes.question}>
                      {modalQuestion?.question}
                    </h4>
                    <div className={classes.input_box}>
                      <input
                        type="text"
                        placeholder="Enter your Answer"
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                      />
                    </div>
                    <div className={classes.btn_container}>
                      <button
                        type="button"
                        className={classes.btn}
                        onClick={(e) => submitHandler(e)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )
        // <div className={classes.overlapContainer}>
        //   <div className={classes.overlapAnimationContainer}>
        //     <Lottie
        //       animationData={correctAnimation}
        //       loop={true}
        //       autoplay={true}
        //       className={classes.overlapAnimation}
        //     />
        //   </div>
        // </div>
      }
    </div>
  );
};

export default InitialQuestionModal;
