import React from "react";
import classes from "./InformationModal.module.css";
import { useNavigate } from "react-router-dom";

const InformationModal = ({ cancelHandler }) => {
  const navigate = useNavigate();
  
  return (
    <div className={classes.modal}>
      <div className={classes.modal_sub_container}>
        <div className={classes.img_container}>
          <img
            src="/assets/images/smile.png"
            alt="info"
            className={classes.image}
          />
        </div>
        <div className={classes.modal_content}>
          <h3 className={classes.modal_title}>Congratulations You have reached the zero point!</h3>
          <p className={classes.paragraph}>
            As you have reached the zero point , you cannot play the game for a day!
            Come back tomorrow and check the leaderboard to check your ranking and know about the prize you have won!
          </p>
          <div>
            <button className={classes.cancel_btn} onClick={cancelHandler}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationModal;
