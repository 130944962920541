import React, { useEffect } from "react";
import classes from "./TermsPage.module.css";
import { useNavigate } from "react-router-dom";

const TermsPage = () => {
  const navigate = useNavigate();
  const navigateToBack = () => {
    navigate("/home");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  return (
    <div className={classes.container}>
      <div className={classes.back_icon_container}>
        <div className={classes.back_icon} onClick={navigateToBack}>
          <i className={`fa-solid fa-arrow-left ${classes.arrow}`}></i>
        </div>
        <div className={classes.logo_text}>
          <h3 className={classes.text}>Zero Point</h3>
        </div>
      </div>
      <div className={classes.sub_container}>
        <div className={classes.terms_container}>
          <div className={classes.heading_text}>
            <h3>Terms & Conditions</h3>
          </div>
          <div className={classes.terms_subContainer}>
            <div className={classes.term}>
              <h3>1. Introduction</h3>
              <p>
                These terms and conditions govern the use of the "ZeroPoint"
                service (hereinafter referred to as "the Service") provided by
                Visiontrek Communication. By accessing or using the Service, you
                agree to comply with and be bound by these terms. If you do not
                agree with any part of these terms, you may not use the Service.
              </p>
            </div>
            <div className={classes.term}>
              <h3>2. Service Overview</h3>
              <p>
                Users will be presented with a set of options, where three
                options are correct, and one is intentionally incorrect. Users
                are required to choose the incorrect option.
              </p>
            </div>
            <div className={classes.term}>
              <h3>3. Eligibility</h3>
              <p>
                To use the Service, there is no age limit. But users must
                provide accurate and complete information during the
                registration process.
              </p>
            </div>
            <div className={classes.term}>
              <h3>4. User Responsibilities</h3>
              <p>
                Users are solely responsible for their choices within the
                ZeroPoint service. Intentional misuse or manipulation of the
                service, including attempts to exploit the system, will result
                in immediate termination of access.
              </p>
            </div>
            <div className={classes.term}>
              <h3>5. Privacy</h3>
              <p>
                We respect user privacy. Information collected during the use of
                the ZeroPoint service will be handled in accordance with our
                privacy policy.
              </p>
            </div>
            <div className={classes.term}>
              <h3>6. Intellectual Property</h3>
              <p>
                All content and materials provided as part of the ZeroPoint
                service, including but not limited to text, graphics, logos, and
                images, are the property of Visiontrek Communication and are
                protected by intellectual property laws. Users may not
                reproduce, distribute, or create derivative works without
                explicit permission.
              </p>
            </div>
            <div className={classes.term}>
              <h3>7. Liability</h3>
              <p>
                Visiontrek Communication is not liable for any direct, indirect,
                incidental, special, or consequential damages arising out of or
                in any way connected with the use of the ZeroPoint service.
              </p>
            </div>
            <div className={classes.term}>
              <h3>8. Termination</h3>
              <p>
                Visiontrek Communication reserves the right to terminate or
                suspend access to the ZeroPoint service at any time without
                prior notice for any reason, including but not limited to a
                violation of these terms.
              </p>
            </div>
            <div className={classes.term}>
              <h3>9. Modifications</h3>
              <p>
                Visiontrek Communication reserves the right to modify or revise
                these terms at any time. Users will be notified of significant
                changes, and continued use of the Service after such changes
                will constitute acceptance of the new terms.
              </p>
            </div>
            <div className={classes.term}>
              <h3>10. Governing Law</h3>
              <p>
                These terms and conditions are governed by and construed in
                accordance with the laws of India. Any disputes arising out of
                or in connection with the Service shall be resolved through
                negotiation or, if necessary, through legal proceedings in the
                courts of India.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
