// const pre=`http://88.99.5.236:9868/`;
const pre=`https://backendzeropoints.gameit.in/`;
export {pre};

const loginApi=`${pre}api/v1/auth/authenticate`;
export {loginApi};

const registerApi=`${pre}api/v1/auth/register`;
export {registerApi};

const saveScoreApi=`${pre}api/v1/score/save`;
export {saveScoreApi};

const leaderboardApi=`${pre}api/v1/score/all`;
export {leaderboardApi};

const modesApi=`${pre}api/v1/score/points`;
export {modesApi};

const easyQuestionsApi=`${pre}api/v1/getQuestions?mode=easy`;
export {easyQuestionsApi};

const mediumQuestionsApi=`${pre}api/v1/getQuestions?mode=medium`;
export {mediumQuestionsApi};

const hardQuestionsApi=`${pre}api/v1/getQuestions?mode=hard`;
export {hardQuestionsApi};

const welcomeQuestionsApi=`${pre}api/v1/getQuestions?mode=welcome`;
export {welcomeQuestionsApi};

const winnerApi=`${pre}api/v1/auth/saveWinnersInfo`;
export {winnerApi};

const userInfoApi=`${pre}api/v1/score/userInfo`;
export {userInfoApi};