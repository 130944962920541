import React from "react";
import Lottie from "lottie-react";
import loseAnimation from "../StaticAnimations/loseAnimation.json";
import classes from "./LosePage.module.css";
import { useNavigate } from "react-router-dom";
import {
  resetHardState,
  resetMediumState,
  setEasyState,
} from "../Slices/gamePlayedSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetEasyQuestions } from "../Slices/easyQuestionsSlice";
import { resetMediumQuestions } from "../Slices/mediumQuestionsSlice";
import { resetHardQuestions } from "../Slices/hardQuestionsSlice";

const LosePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateToGame = () => {
    dispatch(resetEasyQuestions());
    dispatch(resetMediumQuestions());
    dispatch(resetHardQuestions());
    navigate("/game/levels");
  };
  const { totalCorrect, totalWrong } = useSelector(
    (state) => state.totalCorrectWrongSlice
  );
  const { score } = useSelector((state) => state.userSlice);

  return (
    <div className={classes.container}>
      <div className={classes.sub_container}>
        <div className={classes.animation_container}>
          <Lottie
            animationData={loseAnimation}
            loop={true}
            autoplay={true}
            className={classes.loseAnimation}
          />
        </div>
        <div className={classes.text_container}>
          <h2 className={classes.text}>OOPS!You Lose</h2>
          <p className={classes.paragraph}>Try again!</p>
        </div>

        <div className={classes.points_container}>
          <div className={classes.points_sub_container}>
            <img
              src="/assets/images/pointIcon.png"
              alt="points"
              className={classes.point_image}
            />
            <h3 className={classes.point_text}>{score}</h3>
          </div>
        </div>

        <div className={classes.game_history_container}>
          <div className={classes.game_history_sub_container}>
            <div className={classes.card_correct}>
              <h3 className={classes.card_text}>Total Correct Answers</h3>
              <p className={classes.card_paragraph}>{totalCorrect}</p>
            </div>
            <div className={classes.card_wrong}>
              <h3 className={classes.card_text}>Total Wrong Answers</h3>
              <p className={classes.card_paragraph}>{totalWrong}</p>
            </div>
          </div>
        </div>

        <div className={classes.btn_container}>
          <div className={classes.back_btn}>
            <button type="button" onClick={() => navigate("/home")}>
              <span className={classes.shadow}></span>
              <span className={classes.edge}></span>
              <span className={`${classes.front} ${classes.text}`}>
                <i className={`fa-solid fa-arrow-left ${classes.icon}`}></i>
                Go Back
              </span>
            </button>
          </div>

          <div className={classes.play_btn}>
            <button type="button" onClick={() => navigateToGame()}>
              <span className={classes.shadow}></span>
              <span className={classes.edge}></span>
              <span className={`${classes.front} ${classes.text}`}>
                <i class={`fa-solid fa-gamepad ${classes.icon}`}></i>
                Play Again
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LosePage;
