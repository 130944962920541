import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./PracticeGamePage.module.css";
import { easyQuestionsApi } from "../Api/api";
import axios from "axios";
import { increaseIndex, resetIndex } from "../Slices/indexSlice";
import { useNavigate } from "react-router-dom";
import { ResetTime, ResetTimeForProgress } from "../Slices/timeSlice";
import Cookies from "js-cookie";
import { motion, useAnimation } from "framer-motion";
import {
  resetCorrectIndex,
  resetWrongIndex,
  storeCorrectIndex,
  storeWrongIndex,
} from "../Slices/correctWrongSlice";
import BackgroundAudio from "../Components/BackgroundAudio";
import CorrectAnswerAudio from "../Components/CorrectAnswerAudio";
import WrongAnswerAudio from "../Components/WrongAnswerAudio";
import Lottie from "lottie-react";
import quizAnimation from "../StaticAnimations/quizAnimation.json";
import questionMarkAnimation from "../StaticAnimations/questionMarkAnimation.json";
import correctAnimation from "../StaticAnimations/correctAnimation.json";
import Loading from "../Components/Loading";
import {
  increaseCorrect,
  increaseWrong,
  resetCorrect,
  resetWrong,
} from "../Slices/totalCorrectWrongSlice";
import { increaseSkip, resetSkip } from "../Slices/skipSlice";
import Stepper from "../Components/Stepper";
import { resetStepper, storeStepper } from "../Slices/stepperSlice";
import {
  decreasePracticeScore,
  increasePracticeScore,
  resetPracticeState,
  setPracticeQuestions,
} from "../Slices/practiceQuestionsSlice";
import {
  resetOriginalCorrectIndex,
  storeOriginalCorrectIndex,
} from "../Slices/originalCorrectSlice";
import ModalPracticeMode from "../Components/GameComponents/ModalPracticeMode";
import { resetEasyQuestions } from "../Slices/easyQuestionsSlice";
import { resetMediumQuestions } from "../Slices/mediumQuestionsSlice";
import { resetHardQuestions } from "../Slices/hardQuestionsSlice";

const PracticeGamePage = () => {
  const [popup, setPopup] = useState(false);
  const controls = useAnimation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [pointerEvents, setPointerEvents] = useState("all");

  const { practiceQuestions, practiceScore } = useSelector(
    (state) => state?.practiceSlice
  );

  const { originalCorrect } = useSelector(
    (state) => state?.originalCorrectSlice
  );

  const { user } = useSelector((state) => state.userSlice);

  // useEffect(() => {
  //   if (!Cookies.get("token")) {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (!Cookies.get("token")) {
      navigate("/");
    }
    if (!practiceQuestions) {
      dispatch(resetStepper());
      dispatch(resetEasyQuestions());
      dispatch(resetMediumQuestions());
      dispatch(resetHardQuestions());
      dispatch(resetSkip());
      dispatch(resetCorrect());
      dispatch(resetWrong());
      dispatch(resetCorrectIndex());
      dispatch(resetWrongIndex());
      dispatch(resetIndex());
      dispatch(ResetTime());
      dispatch(ResetTimeForProgress());
      dispatch(resetPracticeState());
      dispatch(resetOriginalCorrectIndex());
      getQuestionsFromBackend();
    } else {
      setLoading(false);
    }
  }, [practiceQuestions, Cookies]);

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, null, document.URL);
    });
  }, [window]);

  const navigateToBack = () => {
    navigate("/home");
  };

  const getQuestionsFromBackend = async () => {
    try {
      setLoading(true);
      let headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const res = await axios.get(`${easyQuestionsApi}&ani=${user}`, {
        headers: headers,
      });
      dispatch(setPracticeQuestions(res?.data?.Easy));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   if (!practiceQuestions || practiceQuestions?.length <= 0) {
  //     getQuestionsFromBackend();
  //   } else {
  //     setLoading(false);
  //   }
  // }, []);

  async function saveScore() {
    // const timeoutId = setTimeout(() => {
    //   navigate("/game/winner");
    // }, 1000);
    // return () => clearTimeout(timeoutId);
    dispatch(resetCorrectIndex());
    dispatch(resetWrongIndex());
    dispatch(resetCorrect());
    dispatch(resetWrong());
    setPopup(true);
  }

  const { index } = useSelector((state) => state?.indexSlice);

  const { correct, wrong } = useSelector((state) => state?.correctWrongSlice);

  const checkAnswerAndIncreaseIndex = (data) => {
    if (index >= practiceQuestions.length - 1) {
      if (practiceQuestions[index]?.correctAnswer == data?.option) {
        dispatch(storeStepper({ index: index, correct: true, skip: false }));
        dispatch(storeCorrectIndex(data?.option));
        dispatch(increaseCorrect());
        dispatch(decreasePracticeScore());
      } else {
        dispatch(storeStepper({ index: index, correct: false, skip: false }));
        dispatch(storeWrongIndex(data?.option));
        dispatch(increaseWrong());
        dispatch(increasePracticeScore());
        dispatch(
          storeOriginalCorrectIndex(practiceQuestions[index]?.correctAnswer)
        );
      }
      saveScore();
    } else {
      if (practiceQuestions[index]?.correctAnswer == data?.option) {
        dispatch(storeCorrectIndex(data?.option));
        dispatch(decreasePracticeScore());
        dispatch(increaseCorrect());
        dispatch(storeStepper({ index: index, correct: true, skip: false }));
        setPointerEvents("none");
        setTimeout(() => {
          const animationConfig = {
            opacity: 0,
            transition: { duration: 0.5 },
          };

          controls.start(animationConfig).then(() => {
            dispatch(resetCorrectIndex());
            dispatch(increaseIndex());
            setPointerEvents("all");
            controls.start({ opacity: 1, transition: { duration: 0.5 } });
          });
        }, 500);
      } else {
        dispatch(storeWrongIndex(data?.option));
        dispatch(increasePracticeScore());
        dispatch(increaseWrong());
        dispatch(storeStepper({ index: index, correct: false, skip: false }));
        dispatch(
          storeOriginalCorrectIndex(practiceQuestions[index]?.correctAnswer)
        );
        setPointerEvents("none");
        setTimeout(() => {
          const animationConfig = {
            opacity: 0,
            transition: { duration: 0.5 },
          };

          controls.start(animationConfig).then(() => {
            dispatch(resetWrongIndex());
            dispatch(increaseIndex());
            dispatch(resetOriginalCorrectIndex());
            setPointerEvents("all");
            controls.start({ opacity: 1, transition: { duration: 0.5 } });
          });
        }, 500);
      }
    }
  };

  const skipHandler = () => {
    if (index >= 9) {
      dispatch(increaseSkip());
      dispatch(increasePracticeScore());
      dispatch(increaseWrong());
      saveScore();
    } else {
      dispatch(increasePracticeScore());
      dispatch(increaseWrong());
      dispatch(increaseSkip());
      dispatch(storeStepper({ index: index, correct: null, skip: true }));
      dispatch(
        storeOriginalCorrectIndex(practiceQuestions[index]?.correctAnswer)
      );
      setTimeout(() => {
        const animationConfig = {
          opacity: 0,
          transition: { duration: 0.2 },
        };
        controls.start(animationConfig).then(() => {
          dispatch(increaseIndex());
          dispatch(resetOriginalCorrectIndex());
          controls.start({ opacity: 1, transition: { duration: 0.2 } });
        });
      }, 500);
    }
  };

  const cancelPopupHandler = () => {
    setPopup(false);
    navigate("/home");
  };

  const playAgainHandlerForPopup = () => {
    setPopup(false);
    getQuestionsFromBackend();
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <BackgroundAudio />
          {correct && <CorrectAnswerAudio />}
          {wrong && <WrongAnswerAudio />}
          <div className={classes.container}>
            <div className={classes.sub_container}>
              <div className={classes.text_container}>
                <div className={classes.back_btn} onClick={navigateToBack}>
                  <i
                    className={`fa-solid fa-arrow-left ${classes.back_icon}`}
                  ></i>
                </div>
                <h3 className={classes.text}>Practice Mode</h3>
              </div>

              <Stepper index={index} />

              <div className={classes.sub_container_sub}>
                <div className={classes.quizAnimationContainer}>
                  <Lottie
                    animationData={quizAnimation}
                    loop={true}
                    autoplay={true}
                    className={classes.quizAnimation}
                  />
                </div>

                <div className={classes.questions_options_container}>
                  <div className={classes.questionMarkContainer}>
                    <Lottie
                      animationData={questionMarkAnimation}
                      loop={true}
                      autoplay={true}
                      className={classes.questionMarkAnimation}
                    />
                  </div>

                  <div className={classes.skip_button}>
                    <button type="button" onClick={skipHandler}>
                      <span className={classes.shadow}></span>
                      <span className={classes.edge}></span>
                      <span className={`${classes.front} ${classes.text}`}>
                        {" "}
                        Skip
                      </span>
                    </button>
                  </div>

                  <motion.div
                    initial={{ opacity: 1 }}
                    animate={controls}
                    className={classes.questions_options_sub_container}
                    style={{ pointerEvents: pointerEvents }}
                  >
                    <div className={classes.question_box}>
                      <h4 className={classes.question_text}>
                        {practiceQuestions[index]?.question}
                      </h4>
                    </div>
                    <div className={classes.options_box}>
                      <div
                        className={
                          originalCorrect === "1"
                            ? `${classes.option_box} ${classes.correct_option_box}`
                            : wrong === "1"
                            ? `${classes.option_box} ${classes.wrong_option_box}`
                            : classes.option_box
                        }
                        onClick={() =>
                          checkAnswerAndIncreaseIndex({ option: "1" })
                        }
                      >
                        <h3 className="text-3xl text-center text-slate-100 font-semibold tracking-wider">
                          {practiceQuestions[index]?.optionA}{" "}
                        </h3>
                        {wrong === "1" && (
                          <i
                            className={`fa-solid fa-circle-xmark ${classes.icon}`}
                          ></i>
                        )}
                      </div>

                      <div
                        className={
                          originalCorrect === "2"
                            ? `${classes.option_box} ${classes.correct_option_box}`
                            : wrong === "2"
                            ? `${classes.option_box} ${classes.wrong_option_box}`
                            : classes.option_box
                        }
                        onClick={() =>
                          checkAnswerAndIncreaseIndex({ option: "2" })
                        }
                      >
                        <h3 className="text-3xl text-center text-slate-100 font-semibold tracking-wider">
                          {practiceQuestions[index]?.optionB}{" "}
                        </h3>
                        {wrong === "2" && (
                          <i
                            className={`fa-solid fa-circle-xmark ${classes.icon}`}
                          ></i>
                        )}
                      </div>

                      <div
                        className={
                          originalCorrect === "3"
                            ? `${classes.option_box} ${classes.correct_option_box}`
                            : wrong === "3"
                            ? `${classes.option_box} ${classes.wrong_option_box}`
                            : classes.option_box
                        }
                        onClick={() =>
                          checkAnswerAndIncreaseIndex({ option: "3" })
                        }
                      >
                        <h3 className="text-3xl text-center text-slate-100 font-semibold tracking-wider">
                          {practiceQuestions[index]?.optionC}{" "}
                        </h3>
                        {wrong === "3" && (
                          <i
                            className={`${classes.icon} fa-solid fa-circle-xmark`}
                          ></i>
                        )}
                      </div>

                      <div
                        className={
                          originalCorrect === "4"
                            ? `${classes.option_box} ${classes.correct_option_box}`
                            : wrong === "4"
                            ? `${classes.option_box} ${classes.wrong_option_box}`
                            : classes.option_box
                        }
                        onClick={() =>
                          checkAnswerAndIncreaseIndex({ option: "4" })
                        }
                      >
                        <h3 className="text-3xl text-center text-slate-100 font-semibold tracking-wider">
                          {practiceQuestions[index]?.optionD}{" "}
                        </h3>
                        {wrong === "4" && (
                          <i
                            className={`${classes.icon} fa-solid fa-circle-xmark`}
                          ></i>
                        )}
                      </div>
                    </div>

                    <div className={classes.scoreContainer}>
                      <h1 className={classes.score}>
                        Dummy Score : {practiceScore}
                      </h1>
                    </div>
                    {correct && (
                      <div className={classes.overlapContainer}>
                        <div className={classes.overlapAnimationContainer}>
                          <Lottie
                            animationData={correct && correctAnimation}
                            loop={true}
                            autoplay={true}
                            className={classes.overlapAnimation}
                          />
                        </div>
                      </div>
                    )}
                  </motion.div>

                  {popup && (
                    <ModalPracticeMode
                      cancelHandler={cancelPopupHandler}
                      playAgainHandler={playAgainHandlerForPopup}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PracticeGamePage;
