import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("time")
  ? JSON.parse(localStorage.getItem("time"))
  : { time: 30, timeForProgress: 1, progress: null };

const timeSlice = createSlice({
  name: "time",
  initialState,
  reducers: {
    DecreaseTime: (state, action) => {
      state.time= state.time - 1;
      localStorage.setItem("time", JSON.stringify(state));
      return state;
    },
    ResetTime: (state, action) => {
      // state.time = 30;
      state.time = 60;
      localStorage.setItem("time", JSON.stringify(state));
      return state;
    },
    IncreaseTimeForProgress: (state, action) => {
      state.timeForProgress = state.timeForProgress + 1;
      localStorage.setItem("time", JSON.stringify(state));
      return state;
    },
    ResetTimeForProgress: (state, action) => {
      state.timeForProgress = 1;
      localStorage.setItem("time", JSON.stringify(state));
      return state;
    },
    SetProgress: (state, action) => {
      // state.progress = -1 * (state.timeForProgress * 3.34 - 100);
      state.progress = -1 * (state.timeForProgress * (100/60) - 100);
      localStorage.setItem("time", JSON.stringify(state));
      return state;
    },
  },
});
export const {
  DecreaseTime,
  ResetTime,
  IncreaseTimeForProgress,
  ResetTimeForProgress,
  SetProgress,
} = timeSlice.actions;
export default timeSlice;