import { configureStore } from "@reduxjs/toolkit";
import InitialQuestionModalSlice from "./Slices/initialQuestionModalSlice";
import easyQuestionsSlice from "./Slices/easyQuestionsSlice";
import mediumQuestionsSlice from "./Slices/mediumQuestionsSlice";
import hardQuestionsSlice from "./Slices/hardQuestionsSlice";
import modalQuestionSlice from "./Slices/modalQuestionSlice";
import userSlice from "./Slices/userSlice";
import indexSlice from "./Slices/indexSlice";
import timeSlice from "./Slices/timeSlice";
import correctWrongSlice from "./Slices/correctWrongSlice";
import totalCorrectWrongSlice from "./Slices/totalCorrectWrongSlice";
import skipSlice from "./Slices/skipSlice";
import stepperSlice from "./Slices/stepperSlice";
import gamePlayedSlice from "./Slices/gamePlayedSlice";
import practiceSlice from "./Slices/practiceQuestionsSlice";
import originalCorrectSlice from "./Slices/originalCorrectSlice";
import mobileMenuSlice from "./Slices/mobileMenuSlice";

const store = configureStore({
  reducer: {
    modal: InitialQuestionModalSlice.reducer,
    easyQuestions:easyQuestionsSlice.reducer,
    mediumQuestions:mediumQuestionsSlice.reducer,
    hardQuestions:hardQuestionsSlice.reducer,
    modalQuestion:modalQuestionSlice.reducer,
    userSlice:userSlice.reducer,
    indexSlice:indexSlice.reducer,
    timeSlice:timeSlice.reducer,
    correctWrongSlice:correctWrongSlice.reducer,
    totalCorrectWrongSlice:totalCorrectWrongSlice.reducer,
    skipSlice:skipSlice.reducer,
    stepperSlice:stepperSlice.reducer,
    gamePlayedSlice:gamePlayedSlice.reducer,
    practiceSlice:practiceSlice.reducer,
    originalCorrectSlice:originalCorrectSlice.reducer,
    mobileMenuSlice:mobileMenuSlice.reducer
  },
});
export default store;