import React from "react";
import classes from "./Loading.module.css";
import Lottie from "lottie-react";
import loadingAnimation from "../StaticAnimations/loadingAnimation.json";

const Loading = () => {
  return (
    <div className={classes.container}>
      <div className={classes.sub_container}>
        <div className={classes.loadingAnimationContainer}>
          <Lottie
            animationData={loadingAnimation}
            loop={true}
            autoplay={true}
            className={classes.loadingAnimation}
          />
        </div>
      </div>
    </div>
  );
};

export default Loading;
