import React from "react";
import classes from "./GamePopup.module.css";
import { resetStepper } from "../../Slices/stepperSlice";
import { resetEasyQuestions } from "../../Slices/easyQuestionsSlice";
import { resetMediumQuestions } from "../../Slices/mediumQuestionsSlice";
import { resetHardQuestions } from "../../Slices/hardQuestionsSlice";
import { resetSkip } from "../../Slices/skipSlice";
import { resetCorrect, resetWrong } from "../../Slices/totalCorrectWrongSlice";
import { resetCorrectIndex, resetWrongIndex } from "../../Slices/correctWrongSlice";
import { resetIndex } from "../../Slices/indexSlice";
import { ResetTime, ResetTimeForProgress } from "../../Slices/timeSlice";
import { resetPracticeState } from "../../Slices/practiceQuestionsSlice";
import { resetOriginalCorrectIndex } from "../../Slices/originalCorrectSlice";
import { useDispatch } from "react-redux";

const ModalPracticeMode = ({
  cancelHandler,
  playAgainHandler,
}) => {
  const dispatch=useDispatch();
  const clearRedux=()=>{
    dispatch(resetStepper());
    dispatch(resetEasyQuestions());
    dispatch(resetMediumQuestions());
    dispatch(resetHardQuestions());
    dispatch(resetSkip());
    dispatch(resetCorrect());
    dispatch(resetWrong());
    dispatch(resetCorrectIndex());
    dispatch(resetWrongIndex());
    dispatch(resetIndex());
    dispatch(ResetTime());
    dispatch(ResetTimeForProgress());
    dispatch(resetPracticeState());
    dispatch(resetOriginalCorrectIndex());
    playAgainHandler();
  }

  return (
    <div className={classes.modal}>
      <div className={classes.modal_sub_container}>
        <div className={classes.img_container}>
          <img
            src="/assets/images/smile.png"
            alt="info"
            className={classes.image}
          />
        </div>
        <div className={classes.modal_content}>
          {/* <h3 className={classes.modal_title}>Great!</h3> */}
          <p className={classes.paragraph}>
            Do you want to play practice mode again!
          </p>
          <div className={classes.buttons_container}>
            <button
              onClick={() => clearRedux()}
              className={classes.play_again_btn}
            >
              Play Again!
            </button>
            <button className={classes.cancel_btn} onClick={cancelHandler}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPracticeMode;
