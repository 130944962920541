import {createSlice} from "@reduxjs/toolkit";

const initialState=localStorage.getItem("gamePlayed")?
JSON.parse(localStorage.getItem("gamePlayed")):
{
    easy:false,
    medium:false,
    hard:false
}

const gamePlayedSlice=createSlice({
    name:"gamePlayed",
    initialState,
    reducers:{
        setEasyState:(state,action)=>{
            state.easy=true;
            localStorage.setItem("gamePlayed",JSON.stringify(state));
            return state;
        },
        resetEasyState:(state,action)=>{
            state.easy=false;
            localStorage.setItem("gamePlayed",JSON.stringify(state));
            return state;
        },
        setMediumState:(state,action)=>{
            state.medium=true;
            localStorage.setItem("gamePlayed",JSON.stringify(state));
            return state;
        },
        resetMediumState:(state,action)=>{
            state.medium=false;
            localStorage.setItem("gamePlayed",JSON.stringify(state));
            return state;
        },
        setHardState:(state,action)=>{
            state.hard=true;
            localStorage.setItem("gamePlayed",JSON.stringify(state));
            return state;
        },
        resetHardState:(state,action)=>{
            state.hard=false;
            localStorage.setItem("gamePlayed",JSON.stringify(state));
            return state;
        },
    }
})

export const {setEasyState,resetEasyState,setMediumState,resetMediumState,setHardState,resetHardState}=gamePlayedSlice.actions;
export default gamePlayedSlice;