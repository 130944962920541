import React from 'react'
import classes from "./Loader.module.css";

const Loader = () => {
  return (
    <div className='flex justify-center items-center w-full'>
        <span className={classes.loader}></span>
    </div>
  )
}

export default Loader