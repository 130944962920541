import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
};

const InitialQuestionModalSlice = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    openModal: (state, action) => {
      state.open = true;
      return state;
    },
    closeModal: (state, action) => {
      state.open = false;
      return state;
    },
  },
});

export const { openModal, closeModal } = InitialQuestionModalSlice.actions;

export default InitialQuestionModalSlice;
